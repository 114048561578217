

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType, FileExportType, TemplateType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { ExportTemplateEntityModel } from '@common-src/entity-model/device-entity';

@Component
export default class DownloadExcelComponent extends FormDialogComponent<ExportTemplateEntityModel> {
    FileExportType: FileExportType = FileExportType.EXPORT;
    submitName = '下载';
    dialogOpen(exportModel: ExportTemplateEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.FileExportType = exportModel.dialogType;
        this.dialogTitle = exportModel.dialogType === FileExportType.EXPORT ? '批量导出' : '下载模板';
        this.submitName = exportModel.dialogType === FileExportType.EXPORT ? '导出' : '下载';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(exportModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        if (this.jtlForm.formModel.type !== TemplateType.PROTOCOL && _.isEmpty(this.jtlForm.formModel.driver)) {
            this.showMessageWarning('该产品没有驱动，请检查');
            return;
        }
        this.startFullScreenLoading('正在导出...');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                if (this.FileExportType === FileExportType.EXPORT) {
                    if (this.jtlForm.formModel.type === TemplateType.PROTOCOL) {
                        this.showMessageWarning('接口未出，稍后对接');
                        this.stopFullScreenLoading();
                        return;
                    }
                    const selectIds = this.jtlForm.formModel.selectedDeviceIds as string[];
                    DeviceService.exportBatchExcelResult(selectIds).then(res => {
                        if (res && res.er !== -1) {
                            this.showMessageError(res.erMessage);
                        } else {
                            this.showMessageSuccess('导出模板成功');
                            this.dialogClose();
                            this.$emit('dialogOK');
                            resolve(null);
                        }
                        resolve(null);
                    }).catch(err => {
                        reject();
                        throw err;
                    }).finally(() => {
                        this.stopFullScreenLoading();
                    });
                } else {
                    DeviceService.exportExcelByDeviceTypeId2(this.jtlForm.formModel).then(res => {
                        if (res && res.er !== -1) {
                            this.showMessageError(res.erMessage);
                        } else {
                            this.showMessageSuccess('导出模板成功');
                            this.dialogClose();
                            resolve(null);
                        }
                        // this.showMessageSuccess('导出模板成功');
                        // this.dialogClose();
                        resolve(null);
                    }).catch(err => {
                        reject();
                        throw err;
                    });
                }
            }).catch(err => {
                reject();
                throw err;
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        });
    }
}
