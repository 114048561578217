import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryModel } from '@common-src/model/query-model';

export class DeviceAttributeEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                scopedSlots: { customRender: 'identifier' }
            },
            {
                title: '数据类型',
                dataIndex: 'definition.type',
                scopedSlots: { customRender: 'type' }
            },
            {
                title: '最新值',
                dataIndex: 'valueDesc',
                scopedSlots: { customRender: 'valueDesc' }
            },
            {
                title: '参考范围',
                dataIndex: 'definitionRefs',
                width: 100,
                scopedSlots: { customRender: 'definitionRefs' }
            },
            {
                title: '指令发送结果',
                dataIndex: 'rpcResult',
                scopedSlots: { customRender: 'rpcResult' }
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                scopedSlots: { customRender: 'updateTime' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 230
            }
        ];
    }
}

export class DeviceAttributeQueryModel extends QueryModel {
    deviceId: string = undefined;
    constructor(deviceId: string) {
        super();
        this.deviceId = deviceId;
    }

    toService() {
        return this.deviceId;
    }
}
