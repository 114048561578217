var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-component" }, [
    _c(
      "div",
      { staticClass: "page-body page-body-margin-left-16" },
      [
        _c(
          "a-row",
          [
            _c(
              "a-col",
              { attrs: { span: 24 } },
              [
                _c("a-table", {
                  attrs: {
                    loading: _vm.listLoading,
                    columns: _vm.TableColumns,
                    "data-source": _vm.protocolModel.clientAttrs,
                    pagination: false,
                    scroll: { y: "65vh" },
                    "row-key": "identifier",
                    size: _vm.TableSize,
                  },
                  on: { change: _vm.tableChange },
                  scopedSlots: _vm._u([
                    {
                      key: "variablename",
                      fn: function (text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              staticClass: "width100",
                              attrs: {
                                "allow-clear": true,
                                placeholder: "请选择变量名",
                                "show-search": "",
                                "option-filter-prop": "title",
                              },
                              model: {
                                value: record.variablename,
                                callback: function ($$v) {
                                  _vm.$set(record, "variablename", $$v)
                                },
                                expression: "record.variablename",
                              },
                            },
                            _vm._l(
                              _vm.protocolModelTypeList,
                              function (option) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: option.value,
                                    attrs: {
                                      value: option.value,
                                      title: option.name,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(option.name) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "address",
                      fn: function (text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.address,
                              callback: function ($$v) {
                                _vm.$set(record, "address", $$v)
                              },
                              expression: "record.address",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "addressWrite",
                      fn: function (text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.addressWrite,
                              callback: function ($$v) {
                                _vm.$set(record, "addressWrite", $$v)
                              },
                              expression: "record.addressWrite",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "attrstring",
                      fn: function (text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.attrstring,
                              callback: function ($$v) {
                                _vm.$set(record, "attrstring", $$v)
                              },
                              expression: "record.attrstring",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "ratio",
                      fn: function (text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.ratio,
                              callback: function ($$v) {
                                _vm.$set(record, "ratio", $$v)
                              },
                              expression: "record.ratio",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "len",
                      fn: function (text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              staticClass: "width100",
                              attrs: {
                                "allow-clear": true,
                                placeholder: "请选择数据类型",
                                "show-search": "",
                                "option-filter-prop": "title",
                              },
                              model: {
                                value: record.len,
                                callback: function ($$v) {
                                  _vm.$set(record, "len", $$v)
                                },
                                expression: "record.len",
                              },
                            },
                            _vm._l(_vm.ModbusDataTypeList, function (option) {
                              return _c(
                                "a-select-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    value: option.value,
                                    title: option.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "knxDataType",
                      fn: function (text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              staticClass: "width100",
                              attrs: {
                                "allow-clear": true,
                                placeholder: "请选择数据类型",
                                "show-search": "",
                                "option-filter-prop": "title",
                              },
                              model: {
                                value: record.dataType,
                                callback: function ($$v) {
                                  _vm.$set(record, "dataType", $$v)
                                },
                                expression: "record.dataType",
                              },
                            },
                            _vm._l(_vm.KNXDataTypeList, function (option) {
                              return _c(
                                "a-select-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    value: option.value,
                                    title: option.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "dataType",
                      fn: function (text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              staticClass: "width100",
                              attrs: {
                                "allow-clear": true,
                                disabled: record.accessMode === "r",
                                placeholder: "请选择数据类型",
                                "show-search": "",
                                "option-filter-prop": "title",
                              },
                              model: {
                                value: record.dataType,
                                callback: function ($$v) {
                                  _vm.$set(record, "dataType", $$v)
                                },
                                expression: "record.dataType",
                              },
                            },
                            _vm._l(_vm.BacnetDataTypeList, function (option) {
                              return _c(
                                "a-select-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    value: option.value,
                                    title: option.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "bitIndex",
                      fn: function (text, record) {
                        return [
                          _c("a-input-number", {
                            staticClass: "width100",
                            model: {
                              value: record.bitIndex,
                              callback: function ($$v) {
                                _vm.$set(record, "bitIndex", $$v)
                              },
                              expression: "record.bitIndex",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "funCode",
                      fn: function (text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              staticClass: "width100",
                              attrs: {
                                "allow-clear": true,
                                placeholder: "请选择功能码",
                                "show-search": "",
                                "option-filter-prop": "title",
                              },
                              model: {
                                value: record.funCode,
                                callback: function ($$v) {
                                  _vm.$set(record, "funCode", $$v)
                                },
                                expression: "record.funCode",
                              },
                            },
                            _vm._l(_vm.ModbusFunCodeList, function (option) {
                              return _c(
                                "a-select-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    value: option.value,
                                    title: option.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.protocol === _vm.protocolType.MODBUS
          ? _c(
              "a-row",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c(
                  "a-col",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      "line-height": "32px",
                      "padding-right": "8px",
                    },
                    attrs: { span: 4 },
                  },
                  [_vm._v(" 全局Modbus数据类型:  ")]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "a-select",
                      {
                        staticClass: "width100",
                        attrs: {
                          "allow-clear": true,
                          placeholder: "请选择数据类型",
                          "show-search": "",
                          "option-filter-prop": "title",
                        },
                        on: { change: _vm.modbusDataTypeChange },
                      },
                      _vm._l(_vm.ModbusDataTypeList, function (option) {
                        return _c(
                          "a-select-option",
                          {
                            key: option.value,
                            attrs: { value: option.value, title: option.name },
                          },
                          [_vm._v(" " + _vm._s(option.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("a-col", { attrs: { span: 2 } }),
                _c(
                  "a-col",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      "line-height": "32px",
                      "padding-right": "8px",
                    },
                    attrs: { span: 4 },
                  },
                  [_vm._v(" 全局Modbus功能码:  ")]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "a-select",
                      {
                        staticClass: "width100",
                        attrs: {
                          "allow-clear": true,
                          placeholder: "请选择功能码",
                          "show-search": "",
                          "option-filter-prop": "title",
                        },
                        on: { change: _vm.modbusFunCodeChange },
                      },
                      _vm._l(_vm.ModbusFunCodeList, function (option) {
                        return _c(
                          "a-select-option",
                          {
                            key: option.value,
                            attrs: { value: option.value, title: option.name },
                          },
                          [_vm._v(" " + _vm._s(option.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }