import { BaseEntityModel } from '@common-src/model/base-model';
import { eventLevelFilter } from '@common-src/filter';
import { DeviceMethodQueryModel } from './device-method-entity';

export class DeviceEventEntityModel extends BaseEntityModel {
    name: string = undefined;
    outputDataShow: any = undefined;
    type: string = undefined;
    identifier: string = undefined;
    ts: string = undefined;
    picList: any = undefined;
    outputData: any = undefined;

    static getTableColumns() {
        return [
            {
                title: '事件名称',
                dataIndex: 'name',
                width: 150
            },
            {
                title: '事件标识符',
                dataIndex: 'identifier',
                width: 200
            },
            {
                title: '事件类型',
                dataIndex: 'type',
                customRender: function(text, record, index) {
                    return eventLevelFilter(text);
                }
            },
            {
                title: '输出参数',
                dataIndex: 'outputDataShow',
                width: 550
            },
            {
                title: '事件时间',
                dataIndex: 'ts'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.name = _.get(json, 'name');
        this.type = _.get(json, 'type');
        this.identifier = _.get(json, 'identifier');
        this.ts = _.get(json, 'ts');
        this.picList = _.get(json, 'picList');
        this.outputData = _.get(json, 'outputData');
        this.outputDataShow = _.get(json, 'outputData') ? JSON.stringify(_.get(json, 'outputData')) : '';
        return this;
    }
}

export class DeviceEventQueryModel extends DeviceMethodQueryModel {
    toService(): any {
        const data = super.toService();
        return data;
    }
}
