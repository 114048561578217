

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlModel, FormControlType } from '@common-src/model/form-control';
import { ICRUDQ } from '@common-src/model/interface';
import DeviceService from '@common-src/service/device';
import { ViewModeType } from '@common-src/model/enum';

@Component
export default class DeviceBatchPositionComponent extends FormDialogComponent<any> {
    private ids: Array<string>;
    dialogOpen(selectIds: any, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.ids = selectIds;
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.dialogTitle = '批量修改安装位置';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem: FormControlModel<string> = {
                    key: 'positionId',
                    label: '安装位置',
                    type: FormControlType.SELECT_SPACE,
                    required: true
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            DeviceService.batchChangePosition(this.ids, this.jtlForm.formModel.positionId).then(res => {
                this.showMessageSuccess('批量修改设备安装位置成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }
}
