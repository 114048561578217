

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceImportExcelEntityModel } from '@common-src/entity-model/device-entity';

@Component
export default class ImportExcelComponent extends FormDialogComponent<DeviceImportExcelEntityModel> {
    isProtocol: boolean;
    dialogOpen(importModel: DeviceImportExcelEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.isProtocol = importModel.isProtocol;
        if (this.isProtocol) {
            this.dialogTitle = '驱动批量导入';
        } else {
            this.dialogTitle = '批量导入设备';
        }
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                if (this.isProtocol) {
                    DeviceService.importDriverExcel(this.jtlForm.formModel).then(res => {
                        if (!res || res.er !== -1) {
                            // 导入失败
                            // 文件流 或者 json的er不等于-1
                            this.showMessageError(res?.erMessage || '驱动批量导入失败');
                            resolve(null);
                        } else {
                            // 导入成功，返回json
                            // this.showMessageSuccess('驱动批量导入成功');
                            this.dialogClose();
                            this.$emit('dialogOK', res.items);
                            resolve(null);
                        }
                    }).catch(err => {
                        reject();
                        throw err;
                    });
                } else {
                    DeviceService.importProductExcel(this.jtlForm.formModel).then(res => {
                        if (!res || res.er !== -1) {
                            // 导入失败
                            // 文件流 或者 json的er不等于-1
                            this.showMessageError(res?.erMessage || '批量导入设备失败');
                            resolve(null);
                        } else {
                            // 导入成功，返回json
                            // this.showMessageSuccess('批量导入设备成功');
                            this.dialogClose();
                            this.$emit('dialogOK', res.items);
                            resolve(null);
                        }
                    }).catch(err => {
                        reject();
                        throw err;
                    });
                }
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
