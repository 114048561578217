






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ThingsModelType } from '@common-src/model/enum';
import { FormControlModel, FormControlType, FormControlDataAttrModel } from '@common-src/model/form-control';
@Component
export default class DriverConfigComponent extends BaseComponent {
    @Prop()
    driverConfig: any;
    @Prop()
    thingsModel: any;
    variablesOption: any = {};

    get enumCloneList() {
        return this.thingsModel.enumList;
    }

    get modelType() {
        return this.thingsModel.thingsModelType;
    }

    mounted() {
        this.init();
    }

    init() {
        _.forEach(this.driverConfig.variables, el => {
            this.variablesOption[el.key] = el.value;
        });
        const commonControlList: Array<FormControlDataAttrModel> = [
            {
                key: 'expression',
                label: '数据转换',
                type: FormControlType.FORM_DATA_DES,
                enumCloneList: this.enumCloneList,
                dataTrueName: this.thingsModel.dataTrueName,
                dataFalseName: this.thingsModel.dataFalseName,
                thingsType: this.thingsModel.thingsModelType,
                value: this.driverConfig
            }
        ];
        (this.$refs.formCommon as any).initFormByControls(commonControlList);
    }

    async getDriverConfig() {
        /** 这里防止信息重复提示 */
        if (!await (this.$refs.formCommon as any).validate()) {
            throw new Error('请检查您提交的数据');
        }

        let dataConversionExpression = null;
        if (this.modelType === ThingsModelType.BOOLEAN) {
            if (_.get(this, 'driverConfig.dataFalseValue') && _.get(this, 'driverConfig.dataTrueValue')) {
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: [
                        { source: _.get(this, 'driverConfig.dataFalseValue'), target: 'false' },
                        { source: _.get(this, 'driverConfig.dataTrueValue'), target: 'true' }
                    ]
                };
            } else if (_.get(this, 'driverConfig.dataFalseValue')) {
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: [
                        { source: _.get(this, 'driverConfig.dataFalseValue'), target: 'false' }
                    ]
                };
            } else if (_.get(this, 'driverConfig.dataTrueValue')) {
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: [
                        { source: _.get(this, 'driverConfig.dataTrueValue'), target: 'true' }
                    ]
                };
            }
        } else if (this.modelType === ThingsModelType.ENUM) {
            if (this.driverConfig.currentEnumList.length) {
                let mapRulesEnum = [];
                _.forEach(this.driverConfig.currentEnumList, item => {
                    const source = item.source;
                    const target = item.target;
                    mapRulesEnum.push({ source, target });
                });
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: mapRulesEnum
                };
            }
        } else if (this.modelType === ThingsModelType.INT || this.modelType === ThingsModelType.FLOAT || this.modelType === ThingsModelType.DOUBLE) {
            const decimalDigit = _.get(this, 'driverConfig.mathOperations[0].params.decimalDigit');
            dataConversionExpression = {
                matchType: 'Math',
                expression: _.get(this, 'driverConfig.expressionOther'),
                mathOperations: [
                    {
                        name: 'Round',
                        params: {
                            decimalDigit: _.isEmpty(decimalDigit) ? null : decimalDigit,
                            roundType: _.get(this, 'driverConfig.mathOperations[0].params.roundType') || null
                        }
                    },
                    {
                        name: 'Absolute',
                        params: _.get(this, 'driverConfig.mathOperations[1].params')
                    }
                ]
            };
        } else {
            dataConversionExpression = {
                matchType: 'Other',
                mapRules: [],
                expression: _.get(this, 'driverConfig.expressionOther')
            };
        }
        return {
            identifier: _.get(this, 'driverConfig.identifier'),
            name: _.get(this, 'driverConfig.name'),
            coefficient: _.get(this, 'driverConfig.ratio'),
            dataConversionExpression: dataConversionExpression,
            pollInterval: _.get(this, 'driverConfig.pollInterval'),
            driverObjectTypePropIdentifier: _.get(this, 'driverConfig.attrType'),
            variables: _.get(this, 'variablesOption')
        };
    }
}
