




























import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import { ThingsFunctionType } from '@common-src/model/enum';
import DeviceTypeService from '@common-src/service/device-type';
import DeviceService from '@common-src/service/device';
import { DeviceUsingModel } from '@common-src/entity-model/device-entity';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';

@Component
export default class FunctionIndexComponent extends BaseComponent {
    ThingsFunctionType = ThingsFunctionType;
    currentFunction: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;

    @Prop()
    deviceTypeModel: any;

    get TableColumns(): Array<any> {
        return _.filter(
            ThingsFunctionEntityModel.getFunctionTabelColumns(),
            (item: any) => {
                if (this.currentFunction === ThingsFunctionType.METHOD || this.currentFunction === ThingsFunctionType.EVENT) {
                    return (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) > -1) &&
                     (item.dataIndex !== 'action' && item.dataIndex !== 'enabled');
                } else {
                    return (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) > -1) && (item.dataIndex !== 'action');
                }
            }
        );
    }

    get FilterDataList(): Array<any> {
        return this.deviceTypeModel.getFunctionList(this.currentFunction);
    }

    enableSaveChange() {
        const params = new DeviceUsingModel().toModel(this.FilterDataList).toService();
        DeviceService.updateUsing(params).then(res => {
            this.showMessageSuccess('保存成功');
        });
    }
}
