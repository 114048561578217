import { DeviceAttributeQueryModel } from './device-attribute-entity';
import { ThingsMethodEntityModel } from './things-method-entity';
import { Moment } from 'moment';
import { dateFormat, kilo, text } from '@common-src/filter';

export const METHOD_STATUS_LIST = [
    JTL.CONSTANT.ALL_OPTION,
    { label: '排队中', value: 'QUEUED' },
    { label: '已发送', value: 'SENT' },
    { label: '已送达', value: 'DELIVERED' },
    { label: '成功', value: 'SUCCESSFUL' },
    { label: '超时', value: 'TIMEOUT' },
    { label: '过期', value: 'EXPIRED' },
    { label: '失败', value: 'FAILED' }
];

export const METHOD_TYPE_LIST = [
    JTL.CONSTANT.ALL_OPTION,
    { label: '写属性', value: 'SETATTR' },
    { label: '服务', value: 'SERVICE' }
];

export class DeviceMethodEntityModel extends ThingsMethodEntityModel {
    status: string = undefined;
    inputDataStr: string = undefined;
    outputDataStr: string = undefined;
    type: string = undefined;
    operatorName: string = undefined;
    duration: number = undefined;
    rpcId: string = undefined;
    rpcResult: string = undefined;
    rpcTimer: any = undefined;

    static getTableColumns() {
        return [
            {
                title: '时间',
                dataIndex: 'createdTime',
                customRender: (text, record, index) => {
                    return dateFormat(record.createdTime);
                }
            },
            {
                title: '类型',
                dataIndex: 'type',
                customRender: (text, record, index) => {
                    return _.get(_.find(METHOD_TYPE_LIST, item => item.value === record.type), 'label');
                }
            },
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '状态',
                dataIndex: 'status',
                customRender: (text, record, index) => {
                    return _.get(_.find(METHOD_STATUS_LIST, item => item.value === record.status), 'label');
                }
            },
            {
                title: '响应时长',
                dataIndex: 'duration',
                customRender: (text, record, index) => {
                    if (record.status === 'SUCCESSFUL') {
                        return `${kilo(record.duration / 1000)}秒`;
                    }
                    return '-';
                }
            },
            // {
            //     title: '标识符',
            //     dataIndex: 'identifier'
            // },
            {
                title: '输入参数',
                dataIndex: 'inputDataStr'
                // scopedSlots: { customRender: 'inputData' }
            },
            {
                title: '输出参数',
                dataIndex: 'outputDataStr'
                // scopedSlots: { customRender: 'outputData' }
            },
            {
                title: '操作用户',
                dataIndex: 'operatorName'
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // this.name = _.get(json, 'eventName');
        const inputData = _.get(json, 'inputData');
        if (typeof (inputData) === 'object' && inputData) {
            this.inputDataStr = JSON.stringify(inputData);
        } else {
            this.inputDataStr = text(inputData) as string;
        }
        const outputData = _.get(json, 'outputData');
        if (typeof (outputData) === 'object' && outputData) {
            this.outputDataStr = JSON.stringify(outputData);
        } else {
            this.outputDataStr = text(outputData) as string;
        }
        return this;
    }
}

export class DeviceMethodQueryModel extends DeviceAttributeQueryModel {
    name: string = undefined;
    // startTime: Moment = undefined;
    // endTime: Moment = undefined;
    timeRange: Array<Moment> = undefined;
    type: string = JTL.CONSTANT.ALL_OPTION.value;
    status: string = JTL.CONSTANT.ALL_OPTION.value;
    // timeRange: Array<Moment> = [
    //     moment().add(-1, 'month'),
    //     moment()
    // ];

    toService(): any {
        if (this.timeRange && this.timeRange.length === 2) {
            return {
                params: {
                    deviceId: this.deviceId,
                    nameOrIdentifier: this.name,
                    type: this.type || undefined,
                    status: this.status || undefined,
                    startTime: this.timeRange[0].startOf('day').toDate().getTime(),
                    endTime: this.timeRange[1].endOf('day').toDate().getTime()
                }
            };
        }
        return {
            params: {
                deviceId: this.deviceId,
                nameOrIdentifier: this.name,
                type: this.type || undefined,
                status: this.status || undefined
            }
        };
    }
}
