




































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { DeviceEntityModel } from '@common-src/entity-model/device-entity';
import { AttributeEntityType, DeviceTypeEnum, ThingsModelType, ViewModeType, ReadWriteEnum } from '@common-src/model/enum';
import DeviceChildListComponent from './child-list.vue';
import DeviceService from '@common-src/service3/device';
import DeviceService2 from '@common-src/service/device';
import DeviceThingsIndexComponent from './device-things-index.vue';
import DeviceAttrComponent from './device-attr.vue';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import DeviceMethodComponent from './method/device-method.vue';
import DeviceProtocolTableComponent from './device-protocol-table.vue';
import { copyToPasteboard } from '@common-src/utils/base_util';
import DriverConfigDialog from '../device-driver/driver-config.dialog.vue';
import DriverConnectDialog from '../device-driver/driver-connect.dialog.vue';
import FunctionIndexComponent from './function-index.vue';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import { ModuleType } from '@common-src/enum/module-type.enum';
import DeviceShadowComponent from './device-shadow.vue';
import calculateByLength from '@common-src/filter/calculate-by-length';

const deviceService = new DeviceService();

@Component({
    components: {
        'device-things-index-component': DeviceThingsIndexComponent,
        'device-child-list-component': DeviceChildListComponent,
        'device-attr-component': DeviceAttrComponent,
        'device-method-component': DeviceMethodComponent,
        'device-shadow-component': DeviceShadowComponent,
        'device-protocol-table-component': DeviceProtocolTableComponent,
        DriverConfigDialog,
        DriverConnectDialog,
        FunctionIndexComponent
    }
})
export default class DeviceDetailDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    calculateByLength = calculateByLength;
    isOnline: boolean = false; // 设备是否在线
    deviceId: string = null;
    AttributeEntityType = AttributeEntityType;
    ThingsModelType = ThingsModelType;
    deviceModel: DeviceEntityModel = new DeviceEntityModel();
    deviceAttrModel: DeviceTypeEntityModel = null;
    serverAttrs: any = [];
    serverAttrsNew: any = [];// 服务端属性数据
    serverAttrsNewClone: any = [];// 服务端属性数据
    driverConnectionClone: any = [];
    IsdeviceAttrEdit: boolean = false;// 服务端属性是否编辑
    deviceAttrs: any = null;
    tabIndex: string = '1';
    saveBtnDisabled: boolean = true;
    clientAttrs:any = null;
    driverModel: any = {};
    driverModelClone : any = {}; // 驱动映射表格编辑数据
    deviceTypeEnum = DeviceTypeEnum;
    isMappingConfigEdit: boolean = false; // 驱动映射表格是否编辑
    validateText: string = ''; // 驱动配置必填项字段名称
    fullDevicePath: string ='';// 驱动连接配置名称
    driveColumns: any = [
        {
            dataIndex: 'index',
            key: 'index',
            title: '序号',
            width: 50
        }, {
            dataIndex: 'name',
            key: 'name',
            title: '名称',
            scopedSlots: { customRender: 'name' },
            width: 140
        }, {
            dataIndex: 'identifier',
            key: 'identifier',
            title: '标识符',
            scopedSlots: { customRender: 'identifier' },
            width: 120
        }
    ];

    get IsShowDeviceAttr() {
        if (this.deviceModel.protocol && _.findIndex(this.serverAttrsNew, (item: any) => item.identifier === 'Param') === -1) {
            return false;
        }
        return true;
    }

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    get slotKeys() {
        return _.map(_.get(this.driverModel, 'driverAttributes[0].variables'), r => r.identifier);
    }

    get variablesList() {
        let data = this.driverModel;
        let variables: Array<string> = [];
        if (this.isMappingConfigEdit) {
            data = this.driverModelClone;
        }
        _.forEach(_.get(data, 'driverAttributes'), r => {
            const item = _.find(r.objectTypeProps, (item) => item.identifier === r.objectTypePropIdentifier)?.variables;
            if (!_.isEmpty(item)) {
                variables.push(...item);
            }
        });
        return _.uniq(variables);
        // return _.filter(_.uniq(variables), r => {
        //     const temp = _.find(this.serverAttrs, i => _.toLower(i.key) === _.toLower(r));
        //     if (temp && !_.isEmpty(this.getAttributeValue(temp))) {
        //         return false;
        //     }
        //     return true;
        // });
    }

    // 对象属性类型关联专有配置
    formatObjectTypeProp(variables:any) {
        return _.filter(_.get(this.driverModel, 'driverAttributes[0].variables'), (item) => variables.includes(item.identifier));
    }
    hasObjectType(record, item) {
        return _.find(_.get(record, 'objectTypeProps'), (r) => r.identifier === record.objectTypePropIdentifier)?.variables.includes(item);
    }

    get driveColumnsData() {
        const tempData = _.cloneDeep(this.driveColumns);
        const identifier = ['knx', 'modbus-tcp', 'bacnet'];

        if (!this.objectTypeIsDefault()) {
            tempData.push({
                dataIndex: 'objectTypePropIdentifier',
                key: 'objectTypePropIdentifier',
                title: '对象属性类型',
                width: 132,
                scopedSlots: { customRender: 'objectTypePropIdentifier' }
            });
        }
        _.forEach(this.formatObjectTypeProp(this.variablesList), r => {
            tempData.push({
                dataIndex: r.identifier,
                key: r.identifier,
                title: r.name,
                scopedSlots: { customRender: r.identifier },
                width: 132
            });
        });
        tempData.push({
            dataIndex: 'commonConfig.coefficient',
            key: 'commonConfig.coefficient',
            title: '系数',
            width: 95,
            scopedSlots: { customRender: 'coefficient' }
        });

        if (this.isShowPollIntervalColumn() || this.isMappingConfigEdit) {
            tempData.push({
                dataIndex: 'commonConfig.pollInterval',
                key: 'commonConfig.pollInterval',
                title: '采集周期',
                width: 95,
                scopedSlots: { customRender: 'pollInterval' }
            });
        }

        if (this.isMappingConfigEdit) {
            tempData.push({
                key: 'action',
                title: '数据转换',
                width: 80,
                scopedSlots: { customRender: 'action' }
            });
        }
        return tempData;
    }

    // 获取自定义属性的数量
    get variablesNum() {
        return this.variablesList.length;
    }

    get serverAttrsData() {
        if (this.IsdeviceAttrEdit) {
            this.serverAttrsNew.map(item => item.value = item.value !== null ? item.value.toString() : item.value);
            this.serverAttrsNewClone = _.cloneDeep(this.serverAttrsNew);
            return this.serverAttrsNewClone || [];
        }
        return this.serverAttrsNew || [];
    }

    get driverConnectionData() {
        if (this.isMappingConfigEdit) {
            this.driverModel.driverConnection.map(item => item.value = item.value !== null ? item.value.toString() : item.value);
            this.driverConnectionClone = _.cloneDeep(this.driverModel.driverConnection);
            return this.driverConnectionClone || [];
        }
        return this.driverModel?.driverConnection || [];
    }

    get driveTableData() {
        if (this.isMappingConfigEdit) {
            this.driverModelClone = _.cloneDeep(this.driverModel);
            return this.driverModelClone?.driverAttributes || [];
        }
        return this.driverModel?.driverAttributes || [];
    }

    isReadType(item) {
        const identifier = ['WriteType', 'WriteAddress'];
        return identifier.includes(item);
    }

    formatShowText(identifier:string, record:any) {
        // 只读 写类型、写地址显示'-'
        if (this.isReadType(identifier) && this.isOnlyReader(record)) {
            return '-';
        }
        const model = _.find(_.get(record, 'variables'), r => r.identifier === identifier);
        if (['enum'].includes(model.type) && !model.value?.includes('(')) {
            return model.specs[model.value];
        }
        return model.value;
    }

    // 数据类型是否为 double float int
    isNumberDataType(record) {
        return [ThingsModelType.DOUBLE, ThingsModelType.FLOAT, ThingsModelType.INT].includes(_.get(record, 'definition.type'));
    }

    coefficientFilter(record) {
        return this.isNumberDataType(record) ? record.commonConfig.coefficient ? record.commonConfig.coefficient + '' : 1 : '-';
    }

    // 是否只读
    isOnlyReader(record:any) {
        return _.get(record, 'definition.accessMode') === ReadWriteEnum.READONLY;
    }

    // 对象属性类型是否为默认
    objectTypeIsDefault() {
        return _.some(_.get(this.driverModel, 'driverAttributes'), r => (_.get(r, 'objectTypePropIdentifier') === 'Default-Default'));
    }

    // 是否显示采集周期
    isShowPollIntervalColumn() {
        return _.some(_.get(this.driverModel, 'driverAttributes'), r => !_.isEmpty(_.get(r, 'commonConfig.pollInterval')));
    }

    drawerShow(model: DeviceEntityModel) {
        this.clientAttrs = null;
        this.isOnline = model.online;
        this.drawerOpen(model.id, model.displayName);
        this.init(model.id);
        this.isMappingConfigEdit = false;
        this.IsdeviceAttrEdit = false;
    }
    // 服务端属性是否必填
    isRequired(item) {
        // 如果是网关设备
        if (this.driverModel.type !== 'DEVICE') {
            return this.driverModel.driverConnection.find(subItem => subItem.identifier === item.identifier) !== undefined ||
        this.driverModel.driverAttributes[0]?.variables.find(sunItem => sunItem.identifier === item.identifier) !== undefined;
        } else {
            return this.driverModel.driverAttributes[0]?.variables.find(subItem => subItem.identifier === item.identifier) !== undefined;
        }
    }

    // 映射配置编辑是否禁用
    isDeviceDisabled(val) {
        return this.serverAttrsNew.find(item => item.identifier === val) !== undefined;
    }

    // 驱动信息编辑是否禁用
    isDisabled(val) {
        return this.serverAttrsNew.find(item => item.identifier === val.identifier) !== undefined;
    }

    init(deviceId: string) {
        this.deviceId = deviceId;
        this.startFullScreenLoading();
        DeviceService2.getDetailMatterModel(deviceId)
            .then(
                res => {
                    this.deviceAttrModel = res;
                }
            ).catch(error => {
                this.deviceAttrModel = new DeviceTypeEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
        DeviceService2.getDeviceAttr(deviceId).then(res => {
            this.serverAttrsNew = res;
        });
        DeviceService2.retrieve(deviceId)
            .then(res => {
                this.deviceModel = res.deviceModel;
                this.serverAttrs = res.serverAttrs;
                // 默认给每个设备协议系数值为1
                if (res.clientAttrs) {
                    _.forEach(res.clientAttrs, item => {
                        if (_.isEmpty(item.ratio)) item.ratio = 1;
                    });
                }
                this.clientAttrs = res.clientAttrs;
            })
            .catch(error => {
                this.deviceModel = new DeviceEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
        // if (this.isEdge) {
        this.initDriver();
        // }
    }

    initDriver() {
        DeviceService.getDriverDetail(this.deviceId).then(res => {
            this.driverModel = {
                identifier: res.driverConnection?.identifier,
                name: res.driverConnection?.name,
                driverConnection: res.driverConnection?.driverConnection,
                type: res.driverConnection?.type,
                driverAttributes: []
            };
            this.fullDevicePath = res.fullDevicePath || '';
            this.initDriveConfigTableData();
        });
    }
    reload() {
        // this.tabIndex = '1';
        this.init(this.deviceId);
    }
    copyCodeClick() {
        if (!this.deviceModel.accessToken) {
            this.showMessageWarning('设备还没有令牌');
            return;
        }
        if (copyToPasteboard(this.deviceModel.accessToken)) {
            this.showMessageSuccess('设备令牌已经复制到剪切板里了');
        }
    }
    copyDeviceIdClick() {
        if (copyToPasteboard(this.deviceId)) {
            this.showMessageSuccess('设备ID已经复制到剪切板里了');
        }
    }
    getSaveBtnStatus(status) {
        this.saveBtnDisabled = status;
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<DeviceEntityModel>).dialogOpen(this.deviceModel, deviceService as any, ViewModeType.UPDATE);
    }
    getAttributeValue(attribute: any) {
        if (_.get(attribute, 'definition.type') === ThingsModelType.PASSWORD) {
            return '●'.repeat((this.getAttributeValueText(attribute) || '').length);
        }
        return this.getAttributeValueText(attribute);
    }
    getAttributeValueText(attribute: any) {
        if (_.get(attribute, 'definition.type') === ThingsModelType.ENUM) {
            return _.get(attribute, `definition.specs.${attribute.value}`) || attribute.value;
        } else if (_.get(attribute, 'definition.type') === ThingsModelType.BOOLEAN) {
            return _.get(attribute, `definition.specs.${attribute.value}`) || attribute.value;
        }
        return attribute.value || _.get(attribute, 'definition.value');
    }
    drawerClose() {
        this.drawerVisible = false;
        this.deviceId = null;
        this.tabIndex = '1';
    }

    driverConnectClick() {
        (this.$refs.driverConnectDialog as DriverConnectDialog).dialogOpen(this.deviceId, this.deviceModel.displayName, this.driverModel.driverConnection);
    }

    driverConfigClick(record) {
        let enumList = [];
        let dataFalseName = '';
        let dataTrueName = '';
        if (record.definition?.type === 'enum') {
            _.forEach(record.definition?.specs, (value, key) => {
                enumList.push({ value: key, name: value });
            });
        } else if (record.definition?.type === 'bool') {
            _.forEach(record.definition?.specs, (value, key) => {
                if (key === 'false') {
                    dataFalseName = value;
                } else {
                    dataTrueName = value;
                }
            });
        }
        this.deviceAttrs = {
            thingsModelType: record.definition?.type,
            enumList: enumList,
            dataFalseName,
            dataTrueName
        };
        (this.$refs.driverConfigDialog as DriverConfigDialog).dialogOpen(this.deviceId, record);
    }
    // 服务端属性是否为编辑状态
    deviceAttrClick(visible: boolean) {
        this.IsdeviceAttrEdit = visible;
        if (visible) {
            this.editMappingConfig(!visible);
        }
    }

    editMappingConfig(visible: boolean) {
        this.isMappingConfigEdit = visible;
        if (visible) {
            this.deviceAttrClick(!visible);
        }
    }
    // 格式化对象属性类型展示
    formatObjectType(data:any[], title:string) {
        return _.find(data, (item) => { return item.identifier === title; })?.name || null;
    }

    // 格式化专有配置对应index
    formatPropConfig(data:any[], title:string) {
        const index = _.findIndex(data, (item) => item.identifier === title);
        return index;
    }

    // 格式化专有配置对应名称
    formatPropConfigText(data:any[], title:string) {
        return _.find(data, (item) => item.identifier === title)?.name || '值';
    }

    // 格式化对象属性类型关联专有配置
    formatPropIdentifierVariables(data:any) {
        const variables = _.find(data.objectTypeProps, (item) => item.identifier === data.objectTypePropIdentifier)?.variables || [];
        const res:any = [];
        _.forEach(data.variables, (item) => {
            if (variables.includes(item.identifier)) {
                if (['enum'].includes(item.type) && !Array.isArray(item.specs)) {
                    item.specs = this.transformObjToOptions(item.specs);
                }
                res.push(item);
            }
        }) || [];
        return res;
    }

    // 格式化单个属性类型
    formatVariables(data:any, identifier:string) {
        const variable = _.find(data.variables, (item) => item.identifier === identifier);
        if (['enum'].includes(variable.type) && !Array.isArray(variable.specs)) {
            variable.specs = this.transformObjToOptions(variable.specs);
        }
        // 如果和服务端属性有相同标识符，填充服务端属性的值
        const isServerAttrValue = _.find(this.serverAttrsNew, (item) => item.identifier === variable.identifier);
        if (isServerAttrValue) {
            variable.value = isServerAttrValue.value;
        }
        return variable;
    }

    // 初始化映射配置表格数据
    initDriveConfigTableData() {
        DeviceService.getMappingsForEditData(this.deviceId).then(res => {
            this.driverModel.driverAttributes = _.map(res, (item, index) => ({ index: index + 1, ...item }));
        });
        this.driverModelClone = _.cloneDeep(this.driverModel);
    }

    // 刷新数据转换保存数据
    refreshConfigData(data) {
        _.find(this.driverModelClone.driverAttributes, item => item.identifier === data.identifier).commonConfig.dataConversionExpression = {
            ...data.dataConversionExpression
        };
    }

    // 对象key value转成options key->value value->name
    transformObjToOptions(obj:any) {
        const options = [];
        if (!_.isEmpty(obj)) {
            for (let key in obj) {
                options.push({
                    value: key,
                    name: obj[key]
                });
            }
        }
        return options;
    }
    transformBoolToOptions(obj:any) {
        const options = [];
        if (!_.isEmpty(obj)) {
            for (let key in obj) {
                options.push({
                    value: key === '1' ? 'true' : 'false' || key,
                    name: obj[key]
                });
            }
        }
        return options;
    }

    // 保存服务端属性
    saveDeviceAttr() {
        let isValidate = '';
        _.forEach(this.serverAttrsNewClone, item => {
            if (this.driverModel.type !== 'DEVICE') {
                if (this.driverModel.driverConnection.find(itemCon => itemCon.identifier === item.identifier) !== undefined && _.isEmpty(item.value) ||
                this.driverModel.driverAttributes[0]?.variables.find(sunItem => sunItem.identifier === item.identifier) !== undefined && _.isEmpty(item.value)) {
                    isValidate = item.name;
                }
            } else {
                if (this.driverModel.driverAttributes[0]?.variables.find(itemCon => itemCon.identifier === item.identifier) !== undefined && _.isEmpty(item.value)) {
                    isValidate = item.name;
                }
            }
        });
        if (!_.isEmpty(isValidate)) {
            this.showMessageError(`${isValidate}为必填项,请检查您提交的数据`);
            return;
        }
        const serverAttrsData = this.serverAttrsNewClone.map((item) => {
            if (item.definition.type === 'bool' && !_.isEmpty(item.value)) {
                item.value = item.value === 'false' ? false : true;
            }
            return item;
        });
        DeviceService.updateDeviceAttr(this.deviceId, serverAttrsData).then(res => {
            this.serverAttrsNew = serverAttrsData;
            this.showMessageSuccess('保存成功');
            this.deviceAttrClick(false);
            this.initDriver();
        }).catch(error => {
            this.deviceAttrClick(false);
        });
    }

    saveDriverInfo() {
        let isValidate = '';
        _.forEach(this.driverModelClone.driverAttributes, item => {
            const variables = this.formatPropIdentifierVariables(item);
            _.forEach(variables, (config: any) => {
                if (config.required && _.isEmpty(config.value)) {
                    isValidate = config.name;
                }
            });
        });

        if (!_.isEmpty(isValidate)) {
            this.showMessageError(`${isValidate}为必填项,请检查您提交的数据`);
            return;
        }
        let variables = {};
        const driverConnectionData = this.driverConnectionClone.map((item) => {
            if (item.type === 'bool' && !_.isEmpty(item.value)) {
                item.value = item.value === 'false' ? false : true;
            }
            return item;
        });
        _.map(driverConnectionData, (item) => {
            variables[item.identifier] = item.value;
        });
        const body = {
            deviceId: this.deviceId,
            driverConnection: this.driverModel.type !== 'DEVICE' ? { variables } : undefined,
            devicePointMappings: this.driveTableData.length ? {
                deviceId: this.deviceId,
                attrDriverPointMappings: _.map(this.driverModelClone.driverAttributes, (item) => {
                    let variables = {};
                    _.forEach(item.variables, (item) => {
                        variables[item.identifier] = item.value;
                    });
                    return {
                        'address': item.address,
                        'coefficient': item.commonConfig.coefficient,
                        'dataConversionExpression': item.commonConfig.dataConversionExpression,
                        'driverObjectTypePropIdentifier': item.objectTypePropIdentifier,
                        'identifier': item.identifier,
                        'pollInterval': item.commonConfig.pollInterval,
                        'variables': variables
                    };
                }) } : undefined
        };
        DeviceService.updateMappingsForEditData(body).then(res => {
            this.driverModel = this.driverModelClone;
            this.driverModel.driverConnection = this.driverConnectionClone;
            this.showMessageSuccess('保存成功');
            this.editMappingConfig(false);
            this.initDriveConfigTableData();
        });
    }
}
