





































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DeviceAttributeEntityModel, DeviceAttributeQueryModel } from '@common-src/entity-model/device-attribute-entity';
import DeviceAttributeService from '@common-src/service/device-attribute';
import DeviceAttributeDataComponent from './device-attribute-data.vue';
import DeviceWriteAttributeComponent from './device-write-attribute.vue';
import DeviceAttributeUpdateRefComponent from './device-attribute-update-ref.vue';
import { ThingsModelTypeModel, RefType } from '@common-src/entity-model/things-model-type-entity';
import RpcComponent from '@common-src/mixins/rpc-component';
import { ThingsModelType } from '@common-src/model/enum';
import { dateFormat, dateFromNow } from '@common-src/filter/date-format';
import calculateByLength from '@common-src/filter/calculate-by-length';

import moment, { Moment } from 'moment';

@Component({
    components: {
        'device-attribute-data-dialog': DeviceAttributeDataComponent,
        'device-write-attribute-dialog': DeviceWriteAttributeComponent,
        'device-attribute-update-ref-component': DeviceAttributeUpdateRefComponent
    }
})
export default class DeviceAttributeListComponent extends Mixins(TableDialogFormComponent, RpcComponent) {
    calculateByLength = calculateByLength;
    dateFormat = dateFormat;
    dateFromNow = dateFromNow;
    DeviceAttributeEntityModel = DeviceAttributeEntityModel;
    ThingsModelType = ThingsModelType;
    @Prop()
    deviceId: string;
    @Prop()
    isOnline: boolean;
    timeInterval: NodeJS.Timeout;
    chartTimeInterval: NodeJS.Timeout;
    currentIndex: number;
    chartVisible: boolean = false;
    currentRow: any = {};
    isRefresh: boolean = false;

    created() {
        this.initTable({
            service: DeviceAttributeService,
            queryModel: new DeviceAttributeQueryModel(this.deviceId),
            tableColumns: DeviceAttributeEntityModel.getTableColumns()
        });
        this.getList();
    }

    deviceOnlineTrendData: any = {
        columns: [],
        rows: []
    };

    deviceOverviewSetting = {
        colors: [ '#005ABB', '#C3D4D8', '#C3D4D8' ]
    };

    definitionRefsFilter(record) {
        if (record?.definition?.refs?.type) {
            switch (record.definition.refs.type) {
                case RefType.RANGE:
                    return `${_.get(record.definition.refs, 'range[0]') || ''} ~ ${_.get(record.definition.refs, 'range[1]') || ''}`;
                case RefType.VALUE:
                    return _.get(record.definition.refs, 'range', []).join(', ');
            }
        }
        return '-';
    }
    updateTimeFilter(text) {
        if (text) {
            return `${dateFormat(text)} (${dateFromNow(text)})`;
        }
        return '-';
    }

    showChart(val) {
        this.currentRow = val;
        this.chartVisible = true;
        if (this.isRefresh) {
            this.chartTimeInterval = setInterval(() => {
                this.initChartData(val);
            }, 5000);
        }
        this.initChartData(val);
    }
    initChartData(val) {
        let startTime, endTime;
        this.deviceOnlineTrendData.columns = ['ts', '数值'];
        startTime = moment(this.currentRow.updateTime).subtract(1, 'hours').toDate().getTime();
        endTime = moment(this.currentRow.updateTime).toDate().getTime();
        DeviceAttributeService.getAttributeValues(this.deviceId, val.identifier, startTime, endTime).then(res => {
            if (res.length > 10) { res = res.slice([0], (10)); }
            if (val.definition.refs) { this.deviceOnlineTrendData.columns = ['ts', '数值', '上限值', '下限值']; }
            this.deviceOnlineTrendData.rows = _.reverse(_.map(res, (item) => {
                return {
                    ts: moment(new Date(item.updateTime)).format('YYYY-MM-DD HH:mm:ss'),
                    数值: item.value,
                    上限值: val.definition.refs?.range[1],
                    下限值: val.definition.refs?.range[0]
                };
            }));
        });
    }
    handleCancel() {
        this.chartVisible = false;
        clearInterval(this.chartTimeInterval);
    }

    viewDataClick(model: any) {
        this.viewClick(model);
    }
    writeAttributeClick(model: any, index: number) {
        (this.$refs.deviceWriteAttributeDialog as DeviceWriteAttributeComponent).writeAttributedialogOpen(model, this.deviceId, this.isOnline);
        this.currentIndex = index;
    }
    onRpcInterval(rpcModel: any) {
        (this.listData[this.currentIndex] as any).rpcId = null;
        (this.listData[this.currentIndex] as any).rpcResult = null;
        if (rpcModel.rpcId) {
            this.initGetRequestResultInterval(this.listData[this.currentIndex], rpcModel.rpcId);
        }
    }
    updateAttributeRefClick(model: any) {
        const thingsModel = new ThingsModelTypeModel().toModel(model.definition);
        (this.$refs.deviceAttributeUpdateRefDialog as DeviceAttributeUpdateRefComponent).attributeUpdateRefdialogOpen(thingsModel, model, this.deviceId);
    }
    switchOnChange(val) {
        this.isRefresh = val;
        if (val) {
            this.timeInterval = setInterval(() => {
                this.getList();
            }, 5000);
            this.getList();
        } else {
            clearInterval(this.timeInterval);
        }
    }

    destroyed() {
        clearInterval(this.timeInterval);
        _.forEach(this.listData, item => {
            this.clearTimer(item);
        });
    }
}

