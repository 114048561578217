


















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import DeviceAttributeListComponent from './attribute/device-attribute-list.vue';
import DeviceExtendAttributeListComponent from './extend-attribute/device-extend-attribute-list.vue';
import DeviceEventListComponent from './event/device-event-list.vue';
// import DeviceMethodListComponent from './method/device-method-list.vue';
import DeviceLogListComponent from './method/device-log-list.vue';
import { ThingsFunctionType } from '@common-src/model/enum';

@Component({
    components: {
        'device-attribute-list-component': DeviceAttributeListComponent,
        'device-event-list-component': DeviceEventListComponent,
        // 'device-method-list-component': DeviceMethodListComponent,
        'device-log-list-component': DeviceLogListComponent,
        'device-extend-attribute-list-component': DeviceExtendAttributeListComponent
    }
})
export default class DeviceThingsIndexComponent extends BaseComponent {
    ThingsFunctionType = ThingsFunctionType;
    @Prop()
    deviceId: string;
    @Prop()
    isOnline: boolean;
    @Prop()
    eventList: any;
    tabIndex: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;
}
