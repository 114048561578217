

















import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import DeviceDriverService, { initAttributeFormControl } from '@common-src/service3/device-driver';
import DeviceService from '@common-src/service3/device';

@Component
export default class DriverConnectDialog extends BaseComponent {
    dialogVisible: boolean = false;
    dialogTitle: string = null;
    deviceTypeId: string = null;

    dialogOpen(deviceTypeId: string, deviceTypeName: string, driverConnection: Array<any>): void {
        this.deviceTypeId = deviceTypeId;
        this.dialogTitle = `配置驱动连接-${deviceTypeName}`;
        this.dialogVisible = true;
        this.$nextTick(() => {
            const controlItems = _.map(driverConnection, item => {
                return initAttributeFormControl(item);
            });
            (this.$refs.formConnect as any).initFormByControls(controlItems);
        });
    }

    dialogOK(): any {
        return (this.$refs.formConnect as any).submitForm().then((ret) => {
            DeviceService.saveDriverConnection(this.deviceTypeId, (this.$refs.formConnect as any).formModel).then(res => {
                this.showMessageSuccess('修改连接配置成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}
