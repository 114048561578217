import { Component } from 'vue-property-decorator';
import { BaseComponent } from './base-component';
import DeviceMethodService from '@common-src/service/device-method';
import { WebSocketConnect } from '@common-src/utils/websocket';
import { UserStoreModule } from '@common-src/store/modules/user';

const BATCH_NO = 'control-batch-rpcId';
let WsSocket = null;
@Component
export default class RpcComponent extends BaseComponent {
    initGetRequestResultInterval(model: any, rpcId: string, isEdge = true) {
        if (isEdge) {
            // this.clearTimer(model);
            // model.rpcId = rpcId;
            // model.rpcTimer = setInterval(() => {
            //     this.getRequestResult(model);
            // }, 5000);
            // this.getRequestResult(model);
            return;
        }
        this.setBatchNoStorage(rpcId);
        this.$message.destroy();
        this.$message['info']({ content: '指令发送中......', duration: 180 });
    }

    getRequestResult(model: any) {
        console.log('getRequestResult', model);
        DeviceMethodService.getRpcResult(model.rpcId).then(res => {
            if (res) {
                if (res.status !== 'QUEUED' && res.status !== 'DELIVERED') {
                    this.clearTimer(model);
                }
                switch (res.status) {
                    case 'EXPIRED':
                        model.rpcResult = '消息过期';
                        break;
                    // case 'NO_ACTIVE_CONNECTION':
                    //     model.rpcResult = '设备未连接';
                    //     break;
                    case 'TIMEOUT':
                        model.rpcResult = '设备超时';
                        break;
                    case 'FAILED':
                        model.rpcResult = '设备处理失败';
                        break;
                    case 'SUCCESSFUL':
                        model.rpcResult = '成功';
                        break;
                    // case 'DELIVERED':
                    //     model.rpcResult = '已送达';
                    //     break;
                }
            }
            this.$forceUpdate();
        });
    }

    clearTimer(model: any) {
        if (model.rpcTimer) {
            clearInterval(model.rpcTimer);
            model.rpcTimer = null;
        }
        model.rpcId = null;
    }

    connectWebsocket() {
        // this.$message['error']('指令发送失败！');
        const token = UserStoreModule.Token;
        const projectId = UserStoreModule.CurrentProjectId;
        const host = location.host;
        if (WsSocket && WsSocket.send) {
            WsSocket.send('tick');
            return;
        }
        const websocketProtocol = location.protocol.indexOf('https') > -1 ? 'wss' : 'ws';
        WsSocket = new WebSocketConnect({
            // path: `${websocketProtocol}://${host}/infra-messagecenter/api/ws/sauth/register?token=${token}&X-Project=${projectId}`,
            path: `${websocketProtocol}://${host}/infra-messagecenter/api/ws/sauth/register`,
            onmessage: (msg) => {
                if (msg && msg.data) {
                    const result = JSON.parse(msg.data);
                    if (!result.type || result.type !== 'RPC') {
                        return;
                    }
                    console.log('onmessage', result);
                    const batchNo = localStorage.getItem(BATCH_NO) || '';
                    const batchNoList = batchNo.split(';');
                    try {
                        const ifShowMsg = this.$route?.path.indexOf('/device-list') > -1;
                        if (batchNoList.indexOf(result.msg?.batchNo) > -1 && ifShowMsg) {
                            // 根据批次号判断确认是本人控制才弹出消息
                            if (!result.msg.succeed) {
                                this.$message.destroy();
                                this.$message['error']('指令发送失败！');
                            } else if (result.msg.succeed) {
                                this.$message.destroy();
                                this.$message['success']('指令发送成功');
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            },
            sendData: 'tick'
        });
        return false;
    }

    setBatchNoStorage(batchNo) {
        let batchNoList = localStorage.getItem(BATCH_NO) || '';
        batchNoList = batchNoList + batchNo + ';';
        localStorage.setItem(BATCH_NO, batchNoList);
    }

    clearBatchNoStorage() {
        localStorage.setItem(BATCH_NO, '');
    }
}
