
















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlType } from '@common-src/model/form-control';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import CommonService from '@common-src/service/common';
import { ImportExcelMatchEntityModel } from '@common-src/entity-model/device-entity';

@Component
export default class ImportExcelMatchComponent extends FormDialogComponent<ImportExcelMatchEntityModel> {
    dialogOpen(importModel: ImportExcelMatchEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '批量匹配参数';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                DeviceService.importExcelMatch(this.jtlForm.formModel).then(res => {
                    this.showMessageSuccess(`已匹配数量${res.successCount}条，未匹配数量${res.failCount}条`);
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
