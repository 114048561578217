var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("设备")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-tabs",
                    {
                      staticClass: "sider-tabs",
                      attrs: { type: "card" },
                      on: { change: _vm.groupAndSpaceChange },
                    },
                    [
                      _c(
                        "a-tab-pane",
                        { key: "group", attrs: { tab: "设备分组" } },
                        [
                          _c("jtl-tree-group-component", {
                            attrs: {
                              "group-id": _vm.GroupId,
                              "group-type": _vm.GroupEntityType.DEVICE,
                            },
                            on: { select: _vm.treeGroupSelect },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "space", attrs: { tab: "物理空间" } },
                        [
                          _c("jtl-tree-space-component", {
                            attrs: { "space-id": _vm.SpaceId },
                            on: { select: _vm.treeSpaceSelect },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "设备查询" },
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                          on: {
                            change: _vm.deviceQueryChange,
                            search: _vm.searchClick,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "设备列表" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra" },
                            slot: "extra",
                          },
                          [
                            _c(
                              "a-popover",
                              {
                                staticClass: "ant-dropdown-trigger",
                                attrs: {
                                  "overlay-class-name": "table-column-popover",
                                  trigger: "click",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "content" },
                                  [
                                    _c(
                                      "a-menu",
                                      {
                                        attrs: { slot: "overlay" },
                                        slot: "overlay",
                                      },
                                      _vm._l(_vm.tableColumns, function (col) {
                                        return _c(
                                          "a-menu-item",
                                          { key: col.dataIndex },
                                          [
                                            _c(
                                              "a-checkbox",
                                              {
                                                attrs: { checked: col.visible },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.tableColumnChange(
                                                      col
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(col.title))]
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-button",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [
                                    _vm._v("列表显示设置"),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: { "table-header-child": "" },
                                on: { click: _vm.refreshClick },
                              },
                              [_vm._v("刷新")]
                            ),
                            _c(
                              "a-dropdown",
                              { attrs: { "table-header-child": "" } },
                              [
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: { click: _vm.deleteBatchClick },
                                      },
                                      [_vm._v("批量删除")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: { click: _vm.groupBatchClick },
                                      },
                                      [_vm._v("批量修改分组")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "3",
                                        on: { click: _vm.positionBatchClick },
                                      },
                                      [_vm._v("批量修改位置")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "4",
                                        on: { click: _vm.unlinkBatchClick },
                                      },
                                      [_vm._v("批量解除关联")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "5",
                                        on: { click: _vm.importExcelClick },
                                      },
                                      [_vm._v("批量导入")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "7",
                                        on: {
                                          click: _vm.importProtocolExcelClick,
                                        },
                                      },
                                      [_vm._v("驱动批量导入")]
                                    ),
                                    !_vm.IsEdge
                                      ? _c(
                                          "a-menu-item",
                                          {
                                            key: "8",
                                            on: { click: _vm.batchEditSys },
                                          },
                                          [_vm._v("批量修改子系统")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "9",
                                        on: { click: _vm.batchExportSys },
                                      },
                                      [_vm._v("批量导出设备")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-button",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [
                                    _vm._v("批量操作"),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: { "table-header-child": "" },
                                on: { click: _vm.downloadExcelClick },
                              },
                              [_vm._v("下载模板")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.addClick(
                                      new _vm.DeviceEntityModel(_vm.GroupId)
                                    )
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                            _c(
                              "a-radio-group",
                              {
                                staticClass: "inline-block",
                                staticStyle: { "margin-left": "16px" },
                                attrs: { "button-style": "solid" },
                                on: { change: _vm.getList },
                                model: {
                                  value: _vm.queryModel.forceRoot,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryModel, "forceRoot", $$v)
                                  },
                                  expression: "queryModel.forceRoot",
                                },
                              },
                              [
                                _c(
                                  "a-radio-button",
                                  { attrs: { value: true } },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "menu-unfold" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-radio-button",
                                  { attrs: { value: false } },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "unordered-list" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.FilterTableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": _vm.TableRowKey,
                            size: _vm.TableSize,
                            "expand-icon": function () {},
                            "expanded-row-keys": _vm.expandedRowKeys,
                            "children-column-name": "Children",
                            "row-selection": {
                              selectedRowKeys: _vm.selectedRowKeys,
                              onChange: _vm.onSelectChange,
                              onSelect: _vm.onSelect,
                            },
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "name",
                                fn: function (text, record) {
                                  return [
                                    _vm.queryModel.forceRoot &&
                                    record.hasChildren
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "table-custom-expand-wrapper",
                                          },
                                          [
                                            _c("a-icon", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: record.expand,
                                                  expression: "record.expand",
                                                },
                                              ],
                                              staticClass:
                                                "table-custom-expand-icon",
                                              attrs: { type: "minus-square" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.expandClick(record)
                                                },
                                              },
                                            }),
                                            _c("a-icon", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !record.expand,
                                                  expression: "!record.expand",
                                                },
                                              ],
                                              staticClass:
                                                "table-custom-expand-icon",
                                              attrs: { type: "plus-square" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.expandClick(record)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    record.id
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "table-custom-expand-label",
                                            attrs: { title: text },
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(record)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(text))]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass:
                                              "table-custom-expand-label",
                                            attrs: { title: text },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadMoreClick(
                                                  record.parent
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(text))]
                                        ),
                                  ]
                                },
                              },
                              {
                                key: "deviceStatus",
                                fn: function (text, record) {
                                  return record.id
                                    ? [
                                        _c("span", {
                                          class:
                                            "status-circle " +
                                            record.Online +
                                            "-circle",
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm._f("deviceStatusFilter")(
                                                record.Online
                                              )
                                            ) +
                                            " / " +
                                            _vm._s(record.Active) +
                                            " "
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                              {
                                key: "keyAttributeList",
                                fn: function (text, record) {
                                  return [
                                    _c(
                                      "a-row",
                                      {
                                        attrs: { type: "flex", gutter: [8, 8] },
                                      },
                                      _vm._l(
                                        record.keyAttributeList,
                                        function (item, index) {
                                          return _c(
                                            "a-col",
                                            { key: index },
                                            [
                                              _c(
                                                "a-tag",
                                                {
                                                  attrs: {
                                                    title:
                                                      item.identifier +
                                                      ":" +
                                                      item.value,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.identifier) +
                                                      ": " +
                                                      _vm._s(item.value) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "action",
                                fn: function (text, record) {
                                  return record.id
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "jtl-edit-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editDeviceClick(
                                                  record
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确认删除?",
                                              "ok-text": "确认",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.deleteClick(record)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "jtl-del-link" },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        ),
                                        record.deviceType !==
                                          _vm.DeviceTypeEnum.DEVICE ||
                                        record.parent
                                          ? [
                                              _c("a-divider", {
                                                attrs: { type: "vertical" },
                                              }),
                                              _c(
                                                "a-dropdown",
                                                {
                                                  staticClass:
                                                    "tree-item-action-wrapper",
                                                  attrs: {
                                                    trigger: ["click"],
                                                    placement: "bottomRight",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "tree-item-action-dot",
                                                      on: {
                                                        click: function (e) {
                                                          return e.preventDefault()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        attrs: {
                                                          type: "ellipsis",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-menu",
                                                    {
                                                      attrs: {
                                                        slot: "overlay",
                                                      },
                                                      slot: "overlay",
                                                    },
                                                    [
                                                      record.deviceType !==
                                                      _vm.DeviceTypeEnum.DEVICE
                                                        ? _c(
                                                            "a-menu-item",
                                                            {
                                                              key: "0",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.linkChildClick(
                                                                      record
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("关联")]
                                                          )
                                                        : _vm._e(),
                                                      record.deviceType !==
                                                      _vm.DeviceTypeEnum.DEVICE
                                                        ? _c(
                                                            "a-menu-item",
                                                            {
                                                              key: "1",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.importExcelClick(
                                                                      record
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("批量导入")]
                                                          )
                                                        : _vm._e(),
                                                      record.parent
                                                        ? _c(
                                                            "a-menu-item",
                                                            {
                                                              key: "2",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.unlinkDeviceChildClick(
                                                                      record
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("解除关联")]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("device-detail-drawer", { ref: "deviceDetailDrawer" }),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("device-link-child-dialog", {
        ref: "formChildDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("device-batch-group-dialog", {
        ref: "deviceBatchGroupDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("device-batch-system-dialog", {
        ref: "DeviceBatchSystemDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("device-batch-position-dialog", {
        ref: "deviceBatchPositionDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("download-excel-dialog", {
        ref: "downloadExcelDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("import-excel-dialog", {
        ref: "importExcelDialog",
        on: { dialogOK: _vm.importExcelBatchClick },
      }),
      _c("import-excel-batch-dialog", {
        ref: "importExcelBatchDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
      _c("import-excel-match-dialog", {
        ref: "importExcelMatchDialog",
        on: { dialogOK: _vm.refreshClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }