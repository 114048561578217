import { BaseEntityModel } from '@common-src/model/base-model';
import { ProtocolType } from '@common-src/model/enum';
import { QueryPageModel } from '@common-src/model/query-model';
import textFilter from '@common-src/filter/text';
import { NUMBER_FLOAT_REGEXP } from '@common-src/model/regexp';

const COMMON_TABLE_COLUMNS = [
    {
        title: '名称',
        dataIndex: 'name'
    },
    {
        title: '标识符',
        dataIndex: 'identifier'
    }
];
export class ProtocolEntityModel extends BaseEntityModel {
    protocol: string;
    clientAttrs: Array<any> = null;
    constructor(protocol: string, clientAttrs: Array<any>, serverAttrs: Array<any>) {
        super();
        this.protocol = protocol;
        this.initAttrs(clientAttrs, serverAttrs);
    }

    initAttrs(clientAttrs: Array<any>, serverAttrs: Array<any>) {
        const serverParam = _.find(serverAttrs, item => item.key === 'Param' && ['string', 'text'].indexOf(item.definition['type']) > -1);
        const serverParamValue = serverParam?.value;
        const serverParamValueList = serverParamValue?.split(';');
        // if (serverParamValueList) {
        switch (this.protocol) {
            case ProtocolType.MODBUS:
                this.initModbusAttrs(clientAttrs, serverParamValueList);
                break;
            case ProtocolType.BACNET:
                this.initBacnetAttrs(clientAttrs, serverParamValueList);
                break;
            case ProtocolType.OPCWEB:
                this.initOpcwebAttrs(clientAttrs, serverParamValueList);
                break;
            case ProtocolType.KNX:
                this.initKNXAttrs(clientAttrs, serverParamValueList);
                break;
        }
        // }
    }

    getAttrsValue() {
        let protocolValue = [];
        switch (this.protocol) {
            case ProtocolType.MODBUS:
                protocolValue = _.map(this.clientAttrs, item => {
                    if (!item.ratio || !NUMBER_FLOAT_REGEXP.test(item.ratio)) {
                        throw new Error('系数不能为空且数据类型必须是float');
                    }
                    if (!item.len) {
                        throw new Error('MODBUS协议中数据类型不能为空');
                    }
                    return `${textFilter(_.get(item, 'address'), '')},${textFilter(_.get(item, 'identifier'), '')},${textFilter(_.get(item, 'ratio'), '')},${textFilter(_.get(item, 'len'), '')},${textFilter(_.get(item, 'bitIndex'), '-1')},${textFilter(_.get(item, 'funCode'), '')};`;
                });
                break;
            case ProtocolType.BACNET:
                protocolValue = _.map(this.clientAttrs, item => {
                    if (!item.ratio || !NUMBER_FLOAT_REGEXP.test(item.ratio)) {
                        throw new Error('系数不能为空且数据类型必须是float');
                    }
                    return `${textFilter(_.get(item, 'variablename'), '')}/${textFilter(_.get(item, 'dataType'), '')}/${textFilter(_.get(item, 'address'), '')},${textFilter(_.get(item, 'identifier'), '')},${textFilter(_.get(item, 'ratio'), '')};`;
                });
                break;
            case ProtocolType.OPCWEB:
                protocolValue = _.map(this.clientAttrs, item => {
                    if (!item.ratio || !NUMBER_FLOAT_REGEXP.test(item.ratio)) {
                        throw new Error('系数不能为空且数据类型必须是float');
                    }
                    return `${textFilter(_.get(item, 'attrstring'), '')},${textFilter(_.get(item, 'identifier'), '')},${textFilter(_.get(item, 'ratio'), '')};`;
                });
                break;
            case ProtocolType.KNX:
                protocolValue = _.map(this.clientAttrs, item => {
                    if (!item.ratio || !NUMBER_FLOAT_REGEXP.test(item.ratio)) {
                        throw new Error('系数不能为空且数据类型必须是float');
                    }
                    return `${textFilter(_.get(item, 'dataType'), '')}|${textFilter(_.get(item, 'address'), '')}|${textFilter(_.get(item, 'addressWrite'), '')},${textFilter(_.get(item, 'identifier'), '')},${textFilter(_.get(item, 'ratio'), '')};`;
                });
                break;
        }
        return protocolValue.join('');
    }

    private initModbusAttrs(clientAttrs: Array<any>, serverParamValueList: Array<string>) {
        this.clientAttrs = _.map(clientAttrs, item => {
            const identifier = item.identifier;
            const serverParamValueItem = _.find(serverParamValueList, paramValue => paramValue.indexOf(`,${identifier},`) > -1);
            const serverParamValueItemList = serverParamValueItem?.split(',');
            const address = _.get(serverParamValueItemList, '[0]');
            const ratio = _.get(serverParamValueItemList, '[2]', 1);
            const len = _.get(serverParamValueItemList, '[3]');
            const bitIndex = _.get(serverParamValueItemList, '[4]', -1);
            const funCode = _.get(serverParamValueItemList, '[5]');
            return {
                name: item.name,
                identifier,
                ratio,
                address,
                len,
                bitIndex,
                funCode
            };
        });
    }
    private initOpcwebAttrs(clientAttrs: Array<any>, serverParamValueList: Array<string>) {
        this.clientAttrs = _.map(clientAttrs, item => {
            const identifier = item.identifier;
            const serverParamValueItem = _.find(serverParamValueList, paramValue => paramValue.indexOf(`,${identifier},`) > -1);
            const serverParamValueItemList = serverParamValueItem?.split(',');
            const attrstring = _.get(serverParamValueItemList, '[0]');
            const ratio = _.get(serverParamValueItemList, '[2]', 1);
            return {
                name: item.name,
                identifier,
                attrstring,
                ratio
            };
        });
    }
    private initBacnetAttrs(clientAttrs: Array<any>, serverParamValueList: Array<string>) {
        this.clientAttrs = _.map(clientAttrs, item => {
            const identifier = item.identifier;
            const serverParamValueItem = _.find(serverParamValueList, paramValue => paramValue.indexOf(`,${identifier},`) > -1);
            const serverParamValueItemList = serverParamValueItem?.split(',');
            const nameAndAddress = _.get(serverParamValueItemList, '[0]') || '';
            const nameAndAddressArray = nameAndAddress.split('/');
            const ratio = _.get(serverParamValueItemList, '[2]', 1);
            if (nameAndAddressArray.length === 2) {
                // 兼容老数据
                return {
                    name: item.name,
                    identifier,
                    ratio,
                    variablename: nameAndAddressArray[0],
                    address: nameAndAddressArray[1],
                    accessMode: item.accessMode
                };
            }
            return {
                name: item.name,
                identifier,
                ratio,
                variablename: nameAndAddressArray[0],
                dataType: nameAndAddressArray[1],
                address: nameAndAddressArray[2],
                accessMode: item.accessMode
            };
        });
    }
    private initKNXAttrs(clientAttrs: Array<any>, serverParamValueList: Array<string>) {
        this.clientAttrs = _.map(clientAttrs, item => {
            const identifier = item.identifier;
            const serverParamValueItem = _.find(serverParamValueList, paramValue => paramValue.indexOf(`,${identifier},`) > -1);
            const serverParamValueItemList = serverParamValueItem?.split(',');
            const typeAndAddressStr = _.get(serverParamValueItemList, '[0]') || '';
            const typeAndAddressArray = typeAndAddressStr.split('|');
            const dataType = _.get(typeAndAddressArray, '[0]');
            const address = _.get(typeAndAddressArray, '[1]');
            const addressWrite = _.get(typeAndAddressArray, '[2]');
            const ratio = _.get(serverParamValueItemList, '[2]', 1);
            return {
                name: item.name,
                identifier,
                ratio,
                dataType,
                address,
                addressWrite
            };
        });
    }

    get TableColumns() {
        switch (this.protocol) {
            case ProtocolType.MODBUS:
                return ProtocolEntityModel.getModbusTableColumns();
            case ProtocolType.BACNET:
                return ProtocolEntityModel.getBacnetTableColumns();
            case ProtocolType.OPCWEB:
                return ProtocolEntityModel.getOPCWebTableColumns();
            case ProtocolType.KNX:
                return ProtocolEntityModel.getKNXTableColumns();
        }
        return null;
    }

    static getModbusTableColumns() {
        return [
            ...COMMON_TABLE_COLUMNS,
            {
                title: '地址值',
                dataIndex: 'address',
                scopedSlots: { customRender: 'address' }
            }, {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }, {
                title: '数据类型',
                dataIndex: 'len',
                scopedSlots: { customRender: 'len' }
            }, {
                title: '位号',
                dataIndex: 'bitIndex',
                scopedSlots: { customRender: 'bitIndex' }
            },
            {
                title: '功能码',
                dataIndex: 'funCode',
                scopedSlots: { customRender: 'funCode' }
            }
        ];
    }
    static getBacnetTableColumns() {
        return [
            ...COMMON_TABLE_COLUMNS,
            {
                title: '变量名',
                dataIndex: 'variablename',
                scopedSlots: { customRender: 'variablename' }
            },
            {
                title: '数据类型',
                dataIndex: 'dataType',
                scopedSlots: { customRender: 'dataType' }
            },
            {
                title: '地址值',
                dataIndex: 'address',
                scopedSlots: { customRender: 'address' }
            },
            {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }
        ];
    }
    static getOPCWebTableColumns() {
        return [
            ...COMMON_TABLE_COLUMNS,
            {
                title: '变量名',
                dataIndex: 'attrstring',
                scopedSlots: { customRender: 'attrstring' }
            },
            {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }
        ];
    }
    static getKNXTableColumns() {
        return [
            ...COMMON_TABLE_COLUMNS,
            {
                title: '数据类型',
                dataIndex: 'dataType',
                scopedSlots: { customRender: 'knxDataType' }
            },
            {
                title: '读地址',
                dataIndex: 'address',
                scopedSlots: { customRender: 'address' }
            },
            {
                title: '写地址',
                dataIndex: 'addressWrite',
                scopedSlots: { customRender: 'addressWrite' }
            },
            {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }
        ];
    }
}
export class ProtocolqueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
