import { DeviceAttributeQueryModel } from './device-attribute-entity';
import { ThingsMethodEntityModel } from './things-method-entity';
import CommonService from '@common-src/service/common';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { Moment } from 'moment';
import { dateFormat, kilo, text } from '@common-src/filter';
import http from '@common-src/service3/http/axios';
import { ModuleType } from '@common-src/enum/module-type.enum';

export const METHOD_STATUS_LIST = [
    JTL.CONSTANT.ALL_OPTION,
    { label: '排队中', value: 'QUEUED' },
    { label: '已发送', value: 'SENT' },
    { label: '已送达', value: 'DELIVERED' },
    { label: '成功', value: 'SUCCESSFUL' },
    { label: '超时', value: 'TIMEOUT' },
    { label: '过期', value: 'EXPIRED' },
    { label: '失败', value: 'FAILED' },
    { label: '删除', value: 'DELETED' }
];

export const METHOD_TYPE_LIST = [
    JTL.CONSTANT.ALL_OPTION,
    { label: '写属性', value: 'SETATTR' },
    { label: '服务调用', value: 'SERVICE' }
];

async function getProductList(params: any): Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/product/list`;
    const res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    return res;
}

async function getDeviceList(params: any): Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/device/list`;
    const res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    return res;
}

export class DeviceMethodEntityModel extends ThingsMethodEntityModel {
    status: string = undefined;
    inputDataStr: string = undefined;
    outputDataStr: string = undefined;
    type: string = undefined;
    operatorName: string = undefined;
    duration: number = undefined;
    rpcId: string = undefined;
    rpcResult: string = undefined;
    rpcTimer: any = undefined;

    static getTableColumns() {
        const tableColumns = [
            {
                title: '控制类型',
                dataIndex: 'typeDesc',
                width: 80
            },
            {
                title: '产品',
                dataIndex: 'productName',
                customRender: (text, record, index) => {
                    return record.productName ? record.productName : '--';
                },
                width: 120
            },
            {
                title: '设备',
                dataIndex: 'entityName',
                customRender: (text, record, index) => {
                    return record.entityName ? record.entityName : '--';
                },
                width: 120
            },
            {
                title: '服务名称',
                dataIndex: 'name',
                customRender: (text, record, index) => {
                    return record.name ? record.name : '--';
                },
                width: 80
            },
            {
                title: JTL.moduleType === ModuleType.EDGE ? '响应时长' : '总响应时长',
                dataIndex: 'duration',
                customRender: (text, record, index) => {
                    if (record.duration) {
                        return `${kilo(record.duration / 1000)}s`;
                    }
                    return '--';
                },
                width: 80
            },
            {
                title: '设备响应',
                dataIndex: 'devResponseTime',
                customRender: (text, record, index) => {
                    if (record.duration === null) {
                        return '--';
                    }
                    return `${record.devResponseTime}ms`;
                },
                width: 80
            },
            {
                title: '输入参数',
                dataIndex: 'inputData',
                customRender: (text, record, index) => {
                    return record.inputData && JSON.stringify(record.inputData);
                },
                width: 200
                // scopedSlots: { customRender: 'inputData' }
            },
            {
                title: '控制详情',
                dataIndex: 'trace',
                scopedSlots: { customRender: 'trace' },
                width: 260
            },
            {
                title: '操作状态',
                dataIndex: 'statusDesc',
                width: 80
            },
            {
                title: '操作用户',
                dataIndex: 'operatorName',
                width: 80
            },
            {
                title: '操作时间',
                dataIndex: 'createdTime',
                customRender: (text, record, index) => {
                    return dateFormat(record.createdTime);
                },
                width: 100
            },
            {
                title: '输出参数',
                dataIndex: 'outputData',
                width: 400,
                customRender: (text, record, index) => {
                    return record.outputData && JSON.stringify(record.outputData);
                }
                // scopedSlots: { customRender: 'outputData' }
            }
            // {
            //     title: '状态',
            //     dataIndex: 'status',
            //     customRender: (text, record, index) => {
            //         return _.get(_.find(METHOD_STATUS_LIST, item => item.value === record.status), 'label');
            //     }
            // },
        ];
        if (JTL.moduleType === ModuleType.EDGE) {
            return _.filter(tableColumns, item => item.dataIndex !== 'devResponseTime');
        }
        return tableColumns;
    }

    toModel(json): any {
        super.toModel(json);
        // this.name = _.get(json, 'eventName');
        const inputData = _.get(json, 'inputData');
        if (typeof (inputData) === 'object' && inputData) {
            this.inputDataStr = JSON.stringify(inputData);
        } else {
            this.inputDataStr = text(inputData) as string;
        }
        const outputData = _.get(json, 'outputData');
        if (typeof (outputData) === 'object' && outputData) {
            this.outputDataStr = JSON.stringify(outputData);
        } else {
            this.outputDataStr = text(outputData) as string;
        }
        return this;
    }
}

export class DeviceMethodQueryModel extends DeviceAttributeQueryModel {
    constructor(deviceId: string) {
        super(deviceId);
        this.deviceId = deviceId;
    }
    name: string = undefined;
    // startTime: Moment = undefined;
    // endTime: Moment = undefined;
    timeRange: Array<Moment> = undefined;
    // status: string = JTL.CONSTANT.ALL_OPTION.value;
    // timeRange: Array<Moment> = [
    //     moment().add(-1, 'month'),
    //     moment()
    // ];
    @QueryControl({
        label: '控制类型',
        type: QueryControlType.SELECT,
        options: METHOD_TYPE_LIST,
        // hasAllOption: true,
        span: 6,
        index: 1
    })
    type: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '产品',
        type: QueryControlType.SELECT,
        // optionsPromise: async function() {
        //     let params = {
        //         page: 1,
        //         limit: 99999
        //     };
        //     const res = await getProductList(params);
        //     return _.map(res.rows, (item) => ({ name: item.name, value: item.id }));
        // },
        optionsPromise: CommonService.getDeviceTypeOptions,
        invisibleFunction: async(res, controlList) => {
            console.log(res, controlList);
            if (controlList) {
                let reqParams = {
                    page: 1,
                    limit: 99999,
                    params: {
                        productId: res.productId
                    }
                };
                const result = await getDeviceList(reqParams);
                controlList[2].options = _.map(result.rows, (item) => ({ label: item.name, value: item.id }));
            }
        },
        // change: async function(formControlList, formControl, formModel, formRules, isFirstLoad) {
        //     debugger;
        //     if (!isFirstLoad) {
        //         formModel.deviceId = null;
        //         let productId = formModel.productId;
        //         let reqParams = {
        //             page: 1,
        //             limit: 99999,
        //             params: {
        //                 productId
        //             }
        //         };
        //         const res = await getDeviceList(reqParams);
        //         formControlList[2].options = _.map(res.rows, (item) => ({ label: item.name, value: item.id }));
        //     }
        // },
        index: 2,
        // hasAllOption: true,
        span: 6
    })
    productId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '设备',
        type: QueryControlType.SELECT,
        optionsPromise: async function() {
            let params = {
                page: 1,
                limit: 99999
            };
            const res = await getDeviceList(params);
            return _.map(res.rows, (item) => ({ name: item.name, value: item.id }));
        },
        // optionsPromise: CommonService.getDeviceList,
        index: 3,
        // hasAllOption: true,
        span: 6
    })
    deviceId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        options: METHOD_STATUS_LIST,
        index: 4,
        // hasAllOption: true,
        span: 6
    })
    status: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '操作用户',
        type: QueryControlType.TEXT,
        span: 6,
        index: 5
    })
    operatorName: string = undefined;

    @QueryControl({
        label: '操作时间',
        type: QueryControlType.SELECT_DATE,
        index: 6,
        span: 6
    })
    range: string = undefined;

    toService(): any {
        if (this.timeRange && this.timeRange.length === 2) {
            return {
                params: {
                    deviceId: this.deviceId || undefined,
                    productId: this.productId || undefined,
                    type: this.type || undefined,
                    status: this.status || undefined,
                    operatorName: this.operatorName || undefined,
                    startTime: this.timeRange[0].startOf('day').toDate().getTime(),
                    endTime: this.timeRange[1].endOf('day').toDate().getTime()
                }
            };
        }
        return {
            params: {
                deviceId: this.deviceId || undefined,
                productId: this.productId || undefined,
                type: this.type || undefined,
                status: this.status || undefined,
                operatorName: this.operatorName || undefined
            }
        };
    }
}
