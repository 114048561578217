
import { message } from 'ant-design-vue';
import { getUserInfo } from '@common-src/service3/user/user';
import { UserStoreModule } from '@common-src/store/modules/user';

export class WebSocketConnect {
    socket: any = null;
    params: any = null;
    path: string = null;
    reconnectCount: number = null;
    sendMsgCount: number = null;
    timer: any = null;
    sendTimer: any = null;

    constructor(params) {
        this.socket = null;
        this.params = params;
        this.reconnectCount = 0; // websocket重连次数
        this.sendMsgCount = 0; // 发送信息次数
        if ('WebSocket' in window) {
            this.init(params);
        } else {
            message.open({
                content: '浏览器不支持，请换浏览器再试',
                type: 'error'
            });
        }
    }

    init(params) {
        if (params.path) {
            const token = UserStoreModule.Token;
            const projectId = UserStoreModule.CurrentProjectId;
            this.path = `${params.path}?token=${token}&X-Project=${projectId}`;
            this.socket = new WebSocket(this.path);
        } else {
            throw new Error('参数socket服务器地址path必须存在');
        }
        this.socket.onopen = () => {
            console.log('连接开启');
            clearTimeout(this.timer);
            clearTimeout(this.sendTimer);
            this.send(params?.sendData);
        };
        this.socket.onclose = () => {
            this.reconnectCount = 0;
            console.log('连接关闭');
            this.reconnect();
        };

        this.socket.onerror = () => {
            this.reconnectCount = 0;
            console.log('连接错误');
        };

        this.socket.onmessage = params.onmessage || this.getMessage;
    }

    getMessage(msg) {
        console.log('收到的消息', msg);
        return msg;
    }

    send(data) {
        try {
            if (this.socket.readyState === 1) {
                this.sendMsgCount = 0;
                clearTimeout(this.sendTimer);
                this.sendTimer = setInterval(() => {
                    this.socket.send('tick');
                }, 10000);
                // console.log("发送成功" + data);
            } else {
                if (this.sendMsgCount <= 10) {
                    console.log(this.socket.readyState);
                    ++this.sendMsgCount;
                    this.sendTimer = setTimeout(() => {
                        this.send(data);
                    }, 100 * this.sendMsgCount);
                } else {
                    this.sendMsgCount = 0;
                    clearTimeout(this.sendTimer);
                    this.reconnectCount = 0;
                    this.reconnect();
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    close() {
        clearTimeout(this.timer);
        clearTimeout(this.sendTimer);
        this.timer = null;
        // this.socket?.close(1000, '手动关闭');
        console.log('调用关闭');
    }

    async reconnect() {
        await getUserInfo();
        this.reconnectCount++;
        // 1.开始重连
        // 2.如果连接不成功要继续重连
        if (this.reconnectCount > 1) {
            return false;
        } else {
            if (this.socket.readyState === 1 || this.socket.readyState === 0) {
                // this.lock = false
                // clearTimeout(s)
                return false;
            } else {
                console.log('开始重连');
                this.timer && clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.init(this.params);
                }, 5000);
            }
        }
    }
}
