





































import { Component, Prop, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DeviceEventEntityModel, DeviceEventQueryModel } from '@common-src/entity-model/device-event-entity';
import DeviceEventService from '@common-src/service/device-event';
import { eventLevelList } from '@common-src/filter/event-level';
import DeviceEventDetailDrawer from './device-event-detail-drawer.vue';

@Component({
    components: {
        'device-event-detail-drawer': DeviceEventDetailDrawer
    }
})
export default class DeviceEventListComponent extends Mixins(TableDialogFormComponent) {
    @Prop()
    deviceId: string;
    @Prop()
    eventList: any;

    created() {
        // this.isAutoQuery = false;
        // this.timeSelect = 0;
        // this.timeRange = [
        //     moment().add(-1, 'month'),
        //     moment()
        // ];
        this.initTable({
            service: DeviceEventService,
            queryModel: new DeviceEventQueryModel(this.deviceId),
            tableColumns: DeviceEventEntityModel.getTableColumns()
        });
        this.getList();
    }
    get AllEventLevelOptions() {
        return this.getAllOptions(eventLevelList);
    }
    handleDetail(record:DeviceEventEntityModel) {
        (this.$refs.deviceEventDetailDrawer as DeviceEventDetailDrawer).drawerShow(record, this.eventList);
    }

    // selectTimeChange() {
    //     if (this.timeSelect > 0) {
    //         this.getData();
    //     }
    // }
    // selectTimeRangeChange() {
    //     if (this.timeRange) {
    //         this.getData();
    //     }
    // }
    // inputChange() {
    //     this.debounceGetList();
    // }
    // levelChange() {
    //     this.getList();
    // }
    // getData() {
    //     try {
    //         var { startTime, endTime } = this.getStartAndEndTime();
    //     } catch (err) {
    //         this.showMessageWarning(err.message);
    //         return;
    //     }
    //     (this.queryModel as DeviceEventQueryModel).startTime = startTime;
    //     (this.queryModel as DeviceEventQueryModel).endTime = endTime;
    //     this.getList();
    // }
}

