var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-shadow-component" },
    [
      _c("jtl-button", { on: { click: _vm.init } }, [_vm._v("刷新")]),
      _c("a-table", {
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.listData,
          pagination: _vm.Pagination,
          scroll: _vm.tableScroll,
          "row-key": _vm.TableRowKey,
          size: _vm.TableSize,
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u(
          [
            {
              key: "action",
              fn: function (text, record) {
                return record.id
                  ? [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确认清空期望值?",
                            "ok-text": "确认",
                            "cancel-text": "取消",
                          },
                          on: {
                            confirm: function ($event) {
                              return _vm.clearDesiredVal(record)
                            },
                          },
                        },
                        [
                          _c("a", { staticClass: "jtl-link" }, [
                            _vm._v("清空期望值"),
                          ]),
                        ]
                      ),
                    ]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }