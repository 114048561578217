























import { Component, Prop, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DeviceExtendAttributeEntityModel, DeviceExtendAttributeQueryModel } from '@common-src/entity-model/device-extend-attribute-entity';
import DeviceExtendAttributeService from '@common-src/service/device-extend-attribute';
import DeviceAttributeDataComponent from '../attribute/device-attribute-data.vue';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import RpcComponent from '@common-src/mixins/rpc-component';

@Component({
    components: {
        'device-attribute-data-dialog': DeviceAttributeDataComponent
    }
})
export default class DeviceExtendAttributeListComponent extends Mixins(TableDialogFormComponent, RpcComponent) {
    DeviceExtendAttributeEntityModel = DeviceExtendAttributeEntityModel;
    @Prop()
    deviceId: string;
    timeInterval: NodeJS.Timeout;
    currentIndex: number;

    created() {
        this.initTable({
            service: DeviceExtendAttributeService,
            queryModel: new DeviceExtendAttributeQueryModel(this.deviceId),
            tableColumns: DeviceExtendAttributeEntityModel.getTableColumns()
        });
        this.getList();
    }

    viewDataClick(model: any) {
        this.viewClick(model);
    }

    switchOnChange(val) {
        if (val) {
            this.timeInterval = setInterval(() => {
                this.getList();
            }, 5000);
            this.getList();
        } else {
            clearInterval(this.timeInterval);
        }
    }
}

