var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component",
      attrs: { title: "设置参考范围", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c("div", { staticClass: "form-edit-component" }, [
        _c(
          "div",
          { staticClass: "jtl-form-component" },
          [
            _vm.formModel
              ? _c(
                  "a-form-model",
                  {
                    ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
                    attrs: { model: _vm.formModel },
                  },
                  [
                    [
                      _vm.ThingsModelType.INT,
                      _vm.ThingsModelType.FLOAT,
                      _vm.ThingsModelType.DOUBLE,
                      _vm.ThingsModelType.STRING,
                      _vm.ThingsModelType.TEXT,
                    ].indexOf(_vm.formModel.thingsModelType) > -1
                      ? _c(
                          "div",
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: { width: "50%" },
                                attrs: {
                                  label: "参考范围",
                                  prop: "refs",
                                  "label-col": { span: 10 },
                                  "wrapper-col": { span: 14 },
                                },
                              },
                              [
                                _c("a-input", {
                                  staticStyle: { width: "140px" },
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "最小值",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.formModel.refs.min,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formModel.refs, "min", $$v)
                                    },
                                    expression: "formModel.refs.min",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "calc(100% - 140px)",
                                    },
                                  },
                                  [_vm._v(" ~ ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: { width: "50%" },
                                attrs: {
                                  prop: "refs",
                                  "wrapper-col": { span: 14 },
                                },
                              },
                              [
                                _c("a-input", {
                                  staticStyle: { width: "140px" },
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "最大值",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.formModel.refs.max,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formModel.refs, "max", $$v)
                                    },
                                    expression: "formModel.refs.max",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : [
                          _vm.ThingsModelType.ENUM,
                          _vm.ThingsModelType.BOOLEAN,
                        ].indexOf(_vm.formModel.thingsModelType) > -1
                      ? _c(
                          "div",
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  label: "参考范围",
                                  prop: "refs",
                                  "label-col": { span: 5 },
                                  "wrapper-col": { span: 14 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { mode: "multiple" },
                                    model: {
                                      value: _vm.formModel.refs.range,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formModel.refs,
                                          "range",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.refs.range",
                                    },
                                  },
                                  _vm._l(_vm.DefaultOptions, function (option) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: option.value,
                                        attrs: { value: option.value },
                                      },
                                      [_vm._v(" " + _vm._s(option.name) + " ")]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("jtl-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.saveRef.bind(this) },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }