
import { ICRUDQ } from '@common-src/model/interface';
import { get } from './request';
import { DeviceExtendAttributeEntityModel, DeviceExtendAttributeQueryModel } from '@common-src/entity-model/device-extend-attribute-entity';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/device`;

class DeviceExtendAttributeService implements ICRUDQ<DeviceExtendAttributeEntityModel, DeviceExtendAttributeQueryModel> {
    async create(model: DeviceExtendAttributeEntityModel): Promise<DeviceExtendAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string): Promise<DeviceExtendAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceExtendAttributeEntityModel): Promise<DeviceExtendAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceExtendAttributeEntityModel): Promise<DeviceExtendAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: DeviceExtendAttributeQueryModel): Promise<any> {
        const url = `${URL_PATH}/ts/latest/${query?.toService()}?type=EXTEND`;
        const res = await get(url);
        _.forEach(res, item => {
            item.rpcId = null;
            item.rpcResult = null;
        });
        return res;
        // return _.map(res, item => item = new DeviceExtendAttributeEntityModel().toModel(item));
    }
}

export default new DeviceExtendAttributeService();
