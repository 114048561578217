var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-attribute-list-component" },
    [
      _c(
        "div",
        { staticClass: "refresh-wrapper", staticStyle: { marginTop: "8px" } },
        [
          _vm._v(" 实时刷新 "),
          _c("a-switch", {
            staticClass: "refresh-switch",
            on: { change: _vm.switchOnChange },
          }),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.listData,
          pagination: false,
          scroll: _vm.tableScroll,
          "row-key": "identifier",
          size: _vm.TableSize,
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u([
          {
            key: "rpcResult",
            fn: function (text, record) {
              return [
                record.rpcId && !record.rpcResult
                  ? _c("a-icon", { attrs: { type: "sync", spin: "" } })
                  : _vm._e(),
                record.rpcResult
                  ? _c(
                      "div",
                      {
                        staticClass: "valueDescWidth ellipsis",
                        attrs: { title: record.rpcResult },
                      },
                      [_vm._v(_vm._s(record.rpcResult))]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "name",
            fn: function (text) {
              return [
                _c(
                  "div",
                  { staticClass: "nameWidth ellipsis", attrs: { title: text } },
                  [_vm._v(_vm._s(text))]
                ),
              ]
            },
          },
          {
            key: "identifier",
            fn: function (text) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "identifierWidth ellipsis",
                    attrs: { title: text },
                  },
                  [_vm._v(_vm._s(text))]
                ),
              ]
            },
          },
          {
            key: "type",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "typeWidth ellipsis",
                    attrs: { title: record.definition.type },
                  },
                  [_vm._v(_vm._s(record.definition.type))]
                ),
              ]
            },
          },
          {
            key: "valueDesc",
            fn: function (text) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "valueDescWidth ellipsis",
                    attrs: { title: text },
                  },
                  [_vm._v(_vm._s(text))]
                ),
              ]
            },
          },
          {
            key: "updateTime",
            fn: function (text) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "updateTimeWidth ellipsis",
                    attrs: { title: _vm.updateTimeFilter(text) },
                  },
                  [_vm._v(_vm._s(_vm.updateTimeFilter(text)))]
                ),
              ]
            },
          },
          {
            key: "definitionRefs",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "definitionRefsWidth ellipsis",
                    attrs: { title: _vm.definitionRefsFilter(record) },
                  },
                  [_vm._v(_vm._s(_vm.definitionRefsFilter(record)))]
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record, index) {
              return [
                [
                  _vm.ThingsModelType.FLOAT,
                  _vm.ThingsModelType.INT,
                  _vm.ThingsModelType.DOUBLE,
                ].includes(record.type)
                  ? [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.showChart(record)
                            },
                          },
                        },
                        [_c("a-icon", { attrs: { type: "line-chart" } })],
                        1
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                    ]
                  : _vm._e(),
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.viewDataClick(record)
                      },
                    },
                  },
                  [_vm._v("查看数据")]
                ),
                record.accessMode === "rw" && record.scope === "CLIENT_SCOPE"
                  ? [
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.writeAttributeClick(record, index)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  : _vm._e(),
                _c("a-divider", { attrs: { type: "vertical" } }),
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.updateAttributeRefClick(record)
                      },
                    },
                  },
                  [_vm._v("属性范围")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("device-attribute-data-dialog", {
        ref: "formDialog",
        attrs: { "device-id": _vm.deviceId },
      }),
      _c("device-write-attribute-dialog", {
        ref: "deviceWriteAttributeDialog",
        on: { dialogOk: _vm.getList, rpcInterval: _vm.onRpcInterval },
      }),
      _c("device-attribute-update-ref-component", {
        ref: "deviceAttributeUpdateRefDialog",
        on: { dialogOk: _vm.getList },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.chartVisible,
            width: 600,
            footer: null,
            "mask-closable": true,
            closable: false,
          },
          on: {
            cancel: function ($event) {
              return _vm.handleCancel()
            },
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.currentRow.name))]),
          _c("jtl-line", {
            attrs: {
              data: _vm.deviceOnlineTrendData,
              "legend-visible": true,
              height: "340px",
              colors: _vm.deviceOverviewSetting.colors,
              settings: { digit: 20 },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }