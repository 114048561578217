





















import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import RpcComponent from '@common-src/mixins/rpc-component';
import DeviceMethodService from '@common-src/service/device-method';
import { DeviceMethodEntityModel } from '@common-src/entity-model/device-method-entity';
import { ViewModeType, ThingsModelType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import { ModuleType } from '@common-src/enum/module-type.enum';

@Component
export default class DeviceMethodComponent extends Mixins(BaseComponent, RpcComponent) {
    @Prop()
    deviceId: string;
    methodList: Array<DeviceMethodEntityModel> = null;

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    mounted() {
        DeviceMethodService.getAllMethod(this.deviceId).then(res => {
            this.methodList = res;
            this.$nextTick(() => {
                this.initInputParamForm();
            });
        });
    }

    initInputParamForm() {
        _.forEach(this.methodList, (item, index) => {
            const controlList: any = _.map(item.inputData, inputItem => {
                const paramType = inputItem.thingsModelTypeModel.thingsModelType;
                const controlItem: any = {
                    key: inputItem.identifier,
                    value: inputItem.thingsModelTypeModel.value,
                    label: inputItem.name,
                    type: null,
                    layout: { labelCol: 6, wrapperCol: 15, width: '100%' }
                };
                switch (paramType) {
                    case ThingsModelType.INT:
                    case ThingsModelType.DOUBLE:
                    case ThingsModelType.FLOAT:
                        controlItem.type = FormControlType.NUMBER;
                        controlItem.inputType = 'number';
                        controlItem.min = inputItem.thingsModelTypeModel.dataMin;
                        controlItem.max = inputItem.thingsModelTypeModel.dataMax;
                        controlItem.step = inputItem.thingsModelTypeModel.step;
                        controlItem.placeholder = `请输入${controlItem.label}`;
                        break;
                    case ThingsModelType.BOOLEAN:
                        controlItem.type = FormControlType.SWITCH;
                        controlItem.checkText = inputItem.thingsModelTypeModel.dataTrueName;
                        controlItem.uncheckText = inputItem.thingsModelTypeModel.dataFalseName;
                        controlItem.value = false;
                        break;
                    case ThingsModelType.ENUM:
                    {
                        controlItem.type = FormControlType.SELECT;
                        // const specs = inputItem.thingsModelTypeModel.enumList;
                        // controlItem.options = _.map(_.keysIn(specs), item => {
                        //     return { name: specs[item], value: item };
                        // });
                        controlItem.options = inputItem.thingsModelTypeModel.enumList;
                        controlItem.placeholder = `请选择${controlItem.label}`;
                        break;
                    }
                    case ThingsModelType.JSON:
                        controlItem.type = FormControlType.TEXT_AREA;
                        controlItem.placeholder = `请输入${controlItem.label}`;
                        controlItem.value = JSON.stringify(controlItem.value);
                        break;
                    case ThingsModelType.STRING:
                    case ThingsModelType.TEXT:
                    default:
                        controlItem.type = FormControlType.TEXT;
                        controlItem.value = '';
                        break;
                }
                return controlItem;
            });
            this.$refs.methodInputParam[index].initFormByControls(controlList, ViewModeType.UPDATE);
        });
    }

    rpcClick(param: {methodModel: DeviceMethodEntityModel, index: number}) {
        const { methodModel, index } = param;
        return new Promise((resolve, reject) => {
            this.$refs.methodInputParam[index].submitForm().then(res => {
                methodModel.rpcResult = null;
                const rpcParam = {
                    deviceId: this.deviceId,
                    method: methodModel.identifier,
                    oneWay: true,
                    params: {}
                };
                _.forEach(methodModel.inputData, item => {
                    rpcParam.params[item.identifier] = this.$refs.methodInputParam[index].formModel[item.identifier];
                });

                // 校验输入参数，无inputData值时不检验，例如：重启
                if (_.isEmpty(rpcParam.params) || this.hasEmptyProperty(rpcParam.params)) {
                    if (!_.isEmpty(methodModel.inputData)) {
                        this.showMessageError('请检查输入参数');
                        reject();
                        return;
                    }
                }
                DeviceMethodService.rpc(rpcParam).then(res => {
                    if (res) {
                        this.initGetRequestResultInterval(methodModel, res, this.IsEdge);
                        if (this.IsEdge) {
                            this.showMessageSuccess('执行成功');
                        }
                    } else {
                        this.showMessageSuccess('执行失败');
                    }
                    resolve(null);
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }

    beforeDestroy() {
        _.forEach(this.methodList, item => {
            this.clearTimer(item);
        });
    }

    hasEmptyProperty(data:any) {
        for (let prop in data) {
            if (data.hasOwnProperty(prop)) {
                if (_.isEmpty(data[prop])) {
                    return true;
                }
            }
        }
        return false;
    }
}
