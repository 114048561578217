
import { DeviceMethodEntityModel, DeviceMethodQueryModel } from '@common-src/entity-model/device-method-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, download } from './request';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/device`;

class DeviceMethodService implements ICRUDQ<DeviceMethodEntityModel, DeviceMethodQueryModel> {
    async create(model: DeviceMethodEntityModel):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceMethodEntityModel):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceMethodEntityModel):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: DeviceMethodQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/services/records/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceMethodEntityModel().toModel(item));
        return res;
    }

    async getAllMethod(deviceId: string):Promise<any> {
        const url = `${URL_PATH}/services/definitions/${deviceId}`;
        const res = await get(url);
        // return res;
        return _.map(res, item => item = new DeviceMethodEntityModel().toModel(item));
    }

    async rpc(params: any):Promise<any> {
        const url = `${URL_PATH}/rpc`;
        const res = await post(url, params);
        return res;
    }

    async getRpcResult(requestId: string):Promise<any> {
        const url = `${URL_PATH}/services/record/${requestId}`;
        const res = await get(url);
        return res;
    }

    async deviceLogExport(params: any): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/device/services/records/export`;
        const res = await download(url, params, 'post');
        return res;
    }
}

export default new DeviceMethodService();
