







































import { Component, Prop } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import DeviceService from '@common-src/service/device';
import DeviceLinkChildDialog from './device-link-child-dialog.vue';
import { DeviceTypeEnum } from '@common-src/model/enum';

@Component({
    components: {
        'device-link-child-dialog': DeviceLinkChildDialog
    }
})
export default class DeviceChildListComponent extends TableDialogFormComponent<DeviceEntityModel, DeviceQueryModel> {
    @Prop()
    deviceModel: DeviceEntityModel;

    created() {
        const deviceQueryModel = new DeviceQueryModel();
        // deviceQueryModel.deviceId = this.deviceModel?.id;
        this.initTable({ service: DeviceService, queryModel: deviceQueryModel, tableColumns: DeviceEntityModel.getTableColumns() });
        // this.getList();
    }

    get ListData() {
        const trimName = _.trim(this.queryModel.name);
        if (trimName) {
            return _.filter(this.deviceModel.children, item => item.displayName.indexOf(trimName) > -1);
        }
        return this.deviceModel.children;
    }

    async addDeviceClick() {
        if (this.deviceModel.deviceType === DeviceTypeEnum.DEVICE) {
            this.showMessageWarning('只有网关才能关联子设备');
            return;
        }
        // this.addClick(this.deviceModel);
        (this.$refs.deviceLinkChildDialog as DeviceLinkChildDialog).dialogOpen(this.deviceModel.id, this.deviceModel.typeId);
    }

    detailClick(id: string) {
        this.queryModel.deviceId = id;
        this.$router.push(`/dashboard/device-detail/${id}`);
        this.$emit('reload');
    }

    unlinkClick(id: string) {
        DeviceService.unlink(id, this.deviceModel.id).then(() => {
            const index = _.findIndex(this.deviceModel.children, item => item.id === id);
            if (index > -1) {
                this.deviceModel.children.splice(index, 1);
            }
        });
        // this.getList();
    }

    formChildDialogOK() {
        this.$emit('reload');
    }
}
