
import { DeviceAttributeEntityModel, DeviceAttributeQueryModel } from '@common-src/entity-model/device-attribute-entity';
import { ThingsAttributeEntityModel } from '@common-src/entity-model/things-attribute-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post } from './request';
import { ModuleType } from '@common-src/enum/module-type.enum';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/device`;

class DeviceAttributeService implements ICRUDQ<DeviceAttributeEntityModel, DeviceAttributeQueryModel> {
    async create(model: DeviceAttributeEntityModel):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceAttributeEntityModel):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceAttributeEntityModel):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: DeviceAttributeQueryModel):Promise<any> {
        const url = `${URL_PATH}/ts/latest/${query?.toService()}?type=BASIC`;
        const res = await get(url);
        _.forEach(res, item => {
            item.rpcId = null;
            item.rpcResult = null;
        });
        return res;
        // return _.map(res, item => item = new DeviceAttributeEntityModel().toModel(item));
    }

    async getAttributeValues(entityId: string, identifier: string, startTime: number, endTime: number):Promise<any> {
        const url = `${URL_PATH}/ts/hist`;
        const params = {
            limit: JTL.CONSTANT.PAGE_SIZE * 5,
            params: {
                deviceId: entityId,
                identifier,
                startTime,
                endTime
            }
        };
        const res = await post(url, params);
        return res;
    }

    async writeAttribute(deviceId: string, params: any):Promise<any> {
        let url = `${URL_PATH}/clientAttr/${deviceId}`;
        if (JTL.moduleType === ModuleType.EDGE) {
            url = `${URL_PATH}/shadow/add/${deviceId}`;
        }
        const res = await post(url, params);
        return res;
    }

    async saveAttributeRef(params: any):Promise<any> {
        const url = `${URL_PATH}/refRange/save`;
        const res = await post(url, params);
        return res;
    }
}

export default new DeviceAttributeService();
