




















































































































































































import { Component, Prop } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { ProtocolEntityModel, ProtocolqueryModel } from '@common-src/entity-model/protocol-entity';
import { ProtocolType } from '@common-src/model/enum';
import { protocolModelTypeList } from '@common-src/filter/protocol-var-type';

const MODBUS_FUN_CODE_LIST = [
    { name: '1: COIL_STATUS', value: '1' },
    { name: '2: INPUT_STATUS', value: '2' },
    { name: '3: HOLDING_REGISTER', value: '3' },
    { name: '4: INPUT_REGISTER', value: '4' }
];
const MODBUS_DATA_TYPE_LIST = [
    { name: '1: BINARY', value: '1' },
    { name: '2: TWO_BYTE_INT_UNSIGNED', value: '2' },
    { name: '3: TWO_BYTE_INT_SIGNED', value: '3' },
    { name: '6: FOUR_BYTE_INT_UNSIGNED_SWAPPED', value: '6' },
    { name: '8: FOUR_BYTE_FLOAT', value: '8' },
    { name: '9: FOUR_BYTE_FLOAT_SWAPPED', value: '9' },
    { name: '14: EIGHT_BYTE_FLOAT', value: '14' },
    { name: '15: EIGHT_BYTE_FLOAT_SWAPPED', value: '15' }
];
const BACNET_DATA_TYPE_LIST = [
    { name: 'BitString', value: 'BitString' },
    { name: 'CharacterString', value: 'CharacterString' },
    { name: 'Boolean', value: 'Boolean' },
    { name: 'SignedInteger', value: 'SignedInteger' },
    { name: 'UnSignedInteger', value: 'UnSignedInteger' },
    { name: 'UnSigned8', value: 'UnSigned8' },
    { name: 'UnSigned16', value: 'UnSigned16' },
    { name: 'UnSigned32', value: 'UnSigned32' },
    { name: 'Double', value: 'Double' },
    { name: 'Real', value: 'Real' },
    { name: 'Date', value: 'Date' },
    { name: 'Enumerated', value: 'Enumerated' },
    { name: 'Time', value: 'Time' }
];
const KNX_DATA_TYPE_LIST = [
    { name: 'switch', value: 'switch' },
    { name: 'bool', value: 'bool' },
    { name: 'dimmer', value: 'dimmer' },
    { name: 'blinds', value: 'blinds' },
    { name: 'string', value: 'string' },
    { name: 'temp', value: 'temp' },
    { name: 'float', value: 'float' },
    { name: 'float4', value: 'float4' },
    { name: 'ucount', value: 'ucount' },
    { name: 'int', value: 'int' },
    { name: 'angle', value: 'angle' },
    { name: 'percent', value: 'percent' }
];

@Component
export default class DeviceProtocolTableComponent extends TableComponent<ProtocolEntityModel, ProtocolqueryModel> {
    protocolModelTypeList = protocolModelTypeList;
    @Prop()
    clientAttrs: Array<any>;
    @Prop()
    serverAttrs: Array<any>;
    @Prop()
    protocol:ProtocolType;
    @Prop()
    deviceName:string;

    protocolType = ProtocolType;
    protocolModel: ProtocolEntityModel = null;

    get TableColumns() {
        // let columns = null;
        // switch (this.protocol) {
        //     case ProtocolType.MODBUS:
        //         columns = ProtocolEntityModel.getModbusTableColumns();
        //         break;
        //     case ProtocolType.BACNET:
        //         columns = ProtocolEntityModel.getBacnetTableColumns();
        //         break;
        //     case ProtocolType.OPCWEB:
        //         columns = ProtocolEntityModel.getOPCWebTableColumns();
        //         break;
        // }
        // return columns;
        return this.protocolModel?.TableColumns;
    }
    get ModbusDataTypeList() {
        return MODBUS_DATA_TYPE_LIST;
    }
    get BacnetDataTypeList() {
        return BACNET_DATA_TYPE_LIST;
    }
    get ModbusFunCodeList() {
        return MODBUS_FUN_CODE_LIST;
    }
    get KNXDataTypeList() {
        return KNX_DATA_TYPE_LIST;
    }
    modbusDataTypeChange(val) {
        _.forEach(this.protocolModel.clientAttrs, item => {
            item.len = val;
        });
    }
    modbusFunCodeChange(val) {
        _.forEach(this.protocolModel.clientAttrs, item => {
            item.funCode = val;
        });
    }
    created() {
        this.protocolModel = new ProtocolEntityModel(this.protocol, this.clientAttrs, this.serverAttrs);
        // const serverParam = _.find(this.serverAttrs, item => item.key === 'Param' && ['string', 'text'].indexOf(item.definition['type']) > -1);
        // const serverParamValue = _.get(serverParam, 'value');
        // if (serverParamValue) {
        //     const serverParamValueList = _.filter(serverParamValue.split(';'), item => item);
        //     if (this.protocol === ProtocolType.MODBUS) {
        //         _.forEach(this.clientAttrs, item => {
        //             for (let i = 0; i < serverParamValueList.length; i++) {
        //                 const serverParamValue = serverParamValueList[i].split(',');
        //                 const serverParamIdentifier = serverParamValue[1];
        //                 const stringValue = _.get(serverParamValue, '[0]');
        //                 const ratio = _.get(serverParamValue, '[2]', 1);
        //                 if (item.identifier === serverParamIdentifier) {
        //                     item.ratio = ratio;
        //                     item.address = stringValue;
        //                     item.len = _.get(serverParamValue, '[3]');
        //                     item.bitIndex = _.get(serverParamValue, '[4]', -1);
        //                     item.funCode = _.get(serverParamValue, '[5]');
        //                     break;
        //                 }
        //             }
        //         });
        //     } else if (this.protocol === ProtocolType.OPCWEB) {
        //         _.forEach(this.clientAttrs, item => {
        //             for (let i = 0; i < serverParamValueList.length; i++) {
        //                 const serverParamValue = serverParamValueList[i].split(',');
        //                 const serverParamIdentifier = serverParamValue[1];
        //                 const stringValue = _.get(serverParamValue, '[0]');
        //                 const ratio = _.get(serverParamValue, '[2]', 1);
        //                 if (item.identifier === serverParamIdentifier) {
        //                     item.ratio = ratio;
        //                     item.attrstring = stringValue;
        //                     break;
        //                 }
        //             }
        //         });
        //     } else if (this.protocol === ProtocolType.BACNET) {
        //         _.forEach(this.clientAttrs, item => {
        //             for (let i = 0; i < serverParamValueList.length; i++) {
        //                 const serverParamValue = serverParamValueList[i].split(',');
        //                 const serverParamIdentifier = serverParamValue[1];
        //                 const nameAndAddress = serverParamValue[0];
        //                 if (item.identifier === serverParamIdentifier) {
        //                     const nameAndAddressArray = nameAndAddress.split('/');
        //                     if (nameAndAddressArray.length === 2) {
        //                         // 兼容老数据
        //                         item.variablename = _.get(nameAndAddressArray, '[0]');
        //                         item.address = _.get(nameAndAddressArray, '[1]');
        //                     } else if (nameAndAddressArray.length === 3) {
        //                         item.variablename = _.get(nameAndAddressArray, '[0]');
        //                         item.dataType = _.get(nameAndAddressArray, '[1]');
        //                         item.address = _.get(nameAndAddressArray, '[2]');
        //                     }
        //                     item.ratio = _.get(serverParamValue, '[2]', 1);
        //                     break;
        //                 }
        //             }
        //         });
        //     }
        // }
    }

    getProtocolValue() {
        return this.protocolModel.getAttrsValue();
    }
}

