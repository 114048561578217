























import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import RpcComponent from '@common-src/mixins/rpc-component';
import DeviceMethodService from '@common-src/service/device-method';
import { DeviceShadowEntityModel } from '@common-src/entity-model/device-shadow-entity';
import { ViewModeType, ThingsModelType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import { ModuleType } from '@common-src/enum/module-type.enum';
import TableComponent from '@common-src/mixins/table-component';
import CommonService from '@common-src/service/common';

@Component
export default class DeviceShadowComponent extends Mixins(BaseComponent, RpcComponent, TableComponent) {
    @Prop()
    deviceId: string;

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    mounted() {
        this.initTable({ tableColumns: DeviceShadowEntityModel.getTableColumns() });
        this.$nextTick(() => {
            this.init();
        });
    }

    init() {
        CommonService.getDeviceShadow(this.deviceId).then(res => {
            this.listData = res;
        });
    }

    clearDesiredVal(model) {
        console.log('clearDesiredVal', model);
        CommonService.clearDeviceShadowDesiredVal(model.id).then(res => {
            this.showMessageSuccess('清空成功');
            this.init();
        });
    }
}
