

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlType } from '@common-src/model/form-control';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { getBizTypes } from '@common-src/service3/common.service';

@Component
export default class DeviceBatchGroupComponent extends FormDialogComponent<any> {
    private ids: Array<string>;
    dialogOpen(selectIds: any, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.ids = selectIds;
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.dialogTitle = '批量修改子系统';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem: any = {
                    key: 'systemId',
                    label: '子系统',
                    optionsPromise: getBizTypes,
                    type: FormControlType.SELECT
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            DeviceService.updateSystemEditData(this.ids, this.jtlForm.formModel.systemId).then(res => {
                this.showMessageSuccess('批量修改子系统成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }
}
