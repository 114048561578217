var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-max",
      attrs: {
        title: "配置属性 " + _vm.deviceName,
        visible: _vm.dialogVisible,
        "destroy-on-close": true,
      },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME }),
      _vm.protocol
        ? _c("device-protocol-table-component", {
            ref: "deviceProtocolTable",
            attrs: {
              "client-attrs": _vm.clientAttrs,
              "server-attrs": _vm.serverAttrs,
              protocol: _vm.protocol,
              "device-name": _vm.deviceName,
            },
          })
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: {
                type: "primary",
                "click-prop": _vm.saveAttrClick.bind(this),
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }