import { VariableOption } from '@common-src/model/enum';

const protocolModelTypeList: Array<{ name: string, value: VariableOption }> = _.filter(_.map(VariableOption, item => {
    let name: string = null;
    switch (item) {
        case VariableOption.ANALOGINPUT:
            name = 'analog-input';
            break;
        case VariableOption.ANALOGOUTPUT:
            name = 'analog-output';
            break;
        case VariableOption.ANALOGVALUE:
            name = 'analog-value';
            break;
        case VariableOption.BINARYINPUT:
            name = 'binary-input';
            break;
        case VariableOption.BINARYOUTPUT:
            name = 'binary-output';
            break;
        case VariableOption.BINARYVALUE:
            name = 'binary-value';
            break;
        case VariableOption.MULTI_STATE_VALUE:
            name = 'multi-state-value';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const protocolModelTypeFilter = (val: VariableOption) => {
    return _.get(_.find(protocolModelTypeList, item => item.value === val), 'name') || '-';
};

export {
    protocolModelTypeFilter,
    protocolModelTypeList
};
