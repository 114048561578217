

















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import DeviceDriverService from '@common-src/service3/device-driver';
import DriverConfigComponent from '../device-driver/driver-config-single.vue';
import DeviceService from '@common-src/service3/device';

@Component({
    components: {
        DriverConfigComponent
    }
})
export default class DriverConfigDialog extends BaseComponent {
    @Prop()
    thingsModel: any;

    dialogVisible: boolean = false;
    dialogTitle: string = null;
    deviceId: string = null;
    driverConfigModel: any = null;
    driverConfig: any;

    dialogOpen(deviceId: string, driverConfig: any): void {
        this.deviceId = deviceId;
        this.driverConfig = driverConfig;
        this.dialogTitle = `配置映射规则-${driverConfig.name}`;
        this.dialogVisible = true;
        DeviceDriverService.getDriverConfigDetail(deviceId, driverConfig.identifier).then(res => {
            this.driverConfigModel = res;
            this.$nextTick(() => {
                (this.$refs.driverConfig as any).init();
            });
        });
    }

    async dialogOK() {
        const driverConfig = await (this.$refs.driverConfig as any).getDriverConfig();
        delete driverConfig.name;
        delete driverConfig.identifier;
        const params = {
            deviceId: this.deviceId,
            identifier: this.driverConfig.identifier,
            address: this.driverConfig.address,
            ...driverConfig
        };
        DeviceService.saveDriverConfig(params).then(res => {
            this.showMessageSuccess('修改配置映射规则成功');
            this.dialogClose();
            this.$emit('dialogOK', {
                identifier: this.driverConfig.identifier,
                ...driverConfig
            });
        });
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}
