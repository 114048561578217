






















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceImportExcelEntityModel } from '@common-src/entity-model/device-entity';
import { download } from '@common-src/service/request';

@Component
export default class ImportExcelBatchDialog extends FormDialogComponent<any> {
    isProtocol: boolean;
    public currentBatchId: string = '';
    importResult: any = {
        totalNum: 0,
        successNum: 0,
        failureNum: 0,
        status: 'IMPORTING'
    };
    dialogOpen(batchId: string): void {
        this.dialogVisible = true;
        this.dialogTitle = '导入中';
        this.currentBatchId = batchId;
        this._waitParse(batchId);
    }

    _waitParse(batchId: string) {
        return new Promise((resolve, reject) => {
            DeviceService.getImportExcelResult(batchId).then(res => {
                this.importResult = res;
                if (res.status === 'COMPLETE') {
                    this.dialogTitle = '导入结果';
                } else {
                    new Promise<void>((resolve) => {
                        setTimeout(() => {
                            this._waitParse(batchId).then(() => resolve());
                        }, 3000);
                    });
                }
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    dialogOK(): any {
        this.$emit('dialogOK');
    }

    dialogClose() {
        if (this.importResult?.status === 'IMPORTING') {
            const confirm = this.$confirm({
                title: '关闭',
                content: `关闭弹窗后导入仍将继续，但是您将无法获知导入结果，
                        是否确认关闭?`,
                onOk: () => {
                    this.dialogVisible = false;
                }
            });
            return;
        }
        this.$emit('dialogOK');
        this.dialogVisible = false;
    }

    downloadDetail() {
        this.startFullScreenLoading('正在下载...');
        return new Promise((resolve, reject) => {
            DeviceService.downloadImportExcelResult(this.currentBatchId).then(res => {
                this.showMessageSuccess('下载成功');
                resolve(null);
            }).catch(err => {
                reject();
                throw err;
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        });
    }
}
