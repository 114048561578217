



























































import { Component, Prop, Mixins } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import DeviceAttributeService from '@common-src/service/device-attribute';
import { ThingsModelType, ViewModeType } from '@common-src/model/enum';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import { ThingsAttributeEntityModel } from '@common-src/entity-model/things-attribute-entity';
import { FormControlType } from '@common-src/model/form-control';
import FormComponent from '@common-src/mixins/form-component';

@Component
export default class DeviceAttributeUpdateRefComponent extends FormDialogComponent<any> {
    formModel: any = null;
    attributeModel: any;
    deviceId: string;
    ThingsModelType = ThingsModelType;

    @Prop()
    disabled: boolean;

    attributeUpdateRefdialogOpen(model: any, attributeModel: any, deviceId: string) {
        this.deviceId = deviceId;
        this.formModel = model;
        this.attributeModel = attributeModel;
        this.dialogVisible = true;
    }

    get DefaultOptions(): Array<{name: string, value: string}> {
        let defaultOptions = null;
        if (this.formModel.thingsModelType === ThingsModelType.BOOLEAN) {
            defaultOptions = [
                { name: this.formModel.dataTrueName, value: 'true' },
                { name: this.formModel.dataFalseName, value: 'false' }
            ];
        } else if (this.formModel.thingsModelType === ThingsModelType.ENUM) {
            defaultOptions = this.formModel.enumList;
        }
        return defaultOptions;
    }

    saveRef() {
        const params = {
            deviceId: this.deviceId,
            identifier: this.attributeModel.identifier,
            refs: _.get(this.formModel.toService(), 'refs')
        };
        return DeviceAttributeService.saveAttributeRef(params).then(res => {
            this.showMessageSuccess('修改成功');
            this.$emit('dialogOk');
            this.dialogVisible = false;
        });
    }
}
