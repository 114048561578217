import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryModel } from '@common-src/model/query-model';
import { dateFormat, dateFromNow } from '@common-src/filter/date-format';

export class DeviceExtendAttributeEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '属性名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '数据类型',
                dataIndex: 'definition.type',
                width: 100
            },
            {
                title: '最新值',
                dataIndex: 'valueDesc',
                width: 150,
                customRender: (text, record, index) => {
                    // 需要在底层做格式化
                    // return kilo(text);
                    return text;
                }
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)} (${dateFromNow(text)})`;
                    }
                    return '-';
                },
                width: 300
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 200
            }
        ];
    }
}

export class DeviceExtendAttributeQueryModel extends QueryModel {
    deviceId: string = undefined;
    constructor(deviceId: string) {
        super();
        this.deviceId = deviceId;
    }

    toService() {
        return this.deviceId;
    }
}
