













































































import { Component, Prop } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ImportExcelMatchEntityModel, DeviceEntityModel, DeviceQueryModel, DeviceImportEntityModel, DeviceImportExcelEntityModel, DeviceCopyEntityModel, ExportTemplateEntityModel } from '@common-src/entity-model/device-entity';
import { DeviceMethodEntityModel, DeviceMethodQueryModel, METHOD_STATUS_LIST, METHOD_TYPE_LIST } from '@common-src/entity-model/device-log-entity';
import DeviceMethodService from '@common-src/service/device-method';
import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import moment, { Moment } from 'moment';
import http from '@common-src/service3/http/axios';

async function getProductList(params: any): Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/product/list`;
    const res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    return res;
}

async function getDeviceList(params: any): Promise<any> {
    const url = `${IOT_BASE_REQUEST_PATH}/device/list`;
    const res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    return res;
}

@Component
export default class DeviceLogListComponent extends TableDialogFormComponent<DeviceMethodEntityModel, DeviceMethodQueryModel> {
    @Prop()
    deviceId: string;

    productList: Array<any> = [];
    deviceList: Array<any> = [];
    exportLoading: Boolean = false;

    created() {
        const queryModel = new DeviceMethodQueryModel(this.deviceId);
        this.isAutoQuery = false;
        this.initTable({
            service: new BaseCRUDQService(`${IOT_BASE_REQUEST_PATH}/device/services/records`, null),
            queryModel,
            tableColumns: DeviceMethodEntityModel.getTableColumns()
        });
        this.getList();
        // let params = {
        //     page: 1,
        //     limit: 99999
        // };
        // this.getProductOptions(params);
        // this.getDeviceOptions(params);
    }

    dateFormat(val) {
        return moment(val).format('YYYY-MM-DD HH:mm:ss');
    }

    async getProductOptions(params) {
        const res = await getProductList(params);
        this.productList = _.map(res.items, (item) => ({ label: item.name, value: item.id }));
        this.productList.unshift(JTL.CONSTANT.ALL_OPTION);
    }

    async getDeviceOptions(params) {
        const res = await getDeviceList(params);
        this.deviceList = _.map(res.items, (item) => ({ label: item.name, value: item.id }));
        this.deviceList.unshift(JTL.CONSTANT.ALL_OPTION);
    }

    handleChange(value) {
        let params = {
            page: 1,
            limit: 99999,
            params: {
                productId: value
            }
        };
        this.getDeviceOptions(params);
    }

    deviceQueryChange(formControl) {
        if (formControl?.key === 'name') {
            const qm = (this.queryModel as any);
            if (qm.name) {
                qm.forceRoot = false;
            }
        }
    }

    exportClick() {
        const params = this.queryModel.toService();
        this.exportLoading = true;
        DeviceMethodService.deviceLogExport(params).then((res) => {
            this.showMessage('下载成功');
        }).finally(() => {
            this.exportLoading = false;
        });
    }

    get MethodTypeOptions() {
        return METHOD_TYPE_LIST;
    }

    get MethodStatusOptions() {
        return METHOD_STATUS_LIST;
    }
}

